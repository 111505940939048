import { ExpandMore } from "@mui/icons-material";
import {
  Paper,
  Typography,
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { useState, SyntheticEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { firestore } from "../../firebase";
import { PointHistory } from "../../model/userTypes";
import { timestampToDayjs } from "../../components/dispatchFunctions";
import { getPhoneNumberFormat } from "../../components/phoneNumberInput";

export const UserInfoPage = () => {
  const navigate = useNavigate();
  const {
    state: { info },
  } = useAuth();

  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const [pointHistory, setPointHistory] = useState<PointHistory[]>([]);

  const onClick = () => {
    navigate("modify");
  };

  useEffect(() => {
    if (!info.customer) navigate("/", { replace: true });
  }, [info]);

  useEffect(() => {
    getPointHistory();
  }, []);

  const getPointHistory = async () => {
    const res = await getDocs(
      query(
        collection(firestore, "user-info", info.uid, "point_history"),
        orderBy("date", "desc")
      )
    );
    let history: PointHistory[] = [];
    res.forEach((result) => {
      if (result.exists()) {
        const data = result.data() as PointHistory;
        history.push(data);
      }
    });
    setPointHistory(history);
  };

  return (
    <>
      <>
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
          <Typography color={"primary"}>회원 정보 확인 페이지입니다</Typography>
        </Paper>
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
          <Typography fontWeight={600}>회원 ID</Typography>
          <Typography>{info.customer!.email}</Typography>
        </Paper>
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
          <Typography fontWeight={600}>성함</Typography>
          <Typography>{info.customer!.name}</Typography>
        </Paper>
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
          <Typography fontWeight={600}>전화번호</Typography>
          <Typography>{getPhoneNumberFormat(info.customer!.phone)}</Typography>
        </Paper>
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
          <Typography fontWeight={600}>현금영수증 번호</Typography>
          <Typography>{info.customer!.cashReceipts}</Typography>
        </Paper>
      </>

      <Paper elevation={3} sx={{ mt: 1 }}>
        <Accordion
          expanded={expanded === "point"}
          onChange={handleChange("point")}
          sx={{ py: 1 }}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ width: "33%", flexShrink: 0 }}
            >
              <Typography fontWeight={600}>내 포인트</Typography>
            </Box>
            <Box
              display={"flex"}
              flex={1}
              sx={{ justifyContent: "end", mr: 3 }}
            >
              <Typography color={"primary"}>{`${
                info.customer?.point.toLocaleString() || 0
              } pt`}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {pointHistory.map((history) => (
              <Paper
                key={history.index}
                elevation={3}
                sx={{ py: 2, px: 2, mt: 1 }}
              >
                <Box display={"flex"} justifyContent={"space-between"} gap={2}>
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    {timestampToDayjs(history.date).format("YYYY.MM.DD (ddd)")}
                  </Typography>
                  <Box
                    display={"flex"}
                    flex={1}
                    gap={4}
                    sx={{ justifyContent: "end", mr: 3 }}
                  >
                    <Typography sx={{ flex: 1 }}>{history.reason}</Typography>
                    {history.type === "지급" ? (
                      <Typography color={"primary"}>
                        {history.point.toLocaleString()}
                      </Typography>
                    ) : (
                      <Typography sx={{ color: "commonColor.alert" }}>
                        - {history.point.toLocaleString()}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Paper>
            ))}
          </AccordionDetails>
        </Accordion>
      </Paper>

      <Button
        variant="contained"
        fullWidth
        sx={{ mt: 2 }}
        onClick={() => onClick()}
      >
        회원정보 수정
      </Button>
      <Box display={"flex"} justifyContent={"flex-end"} mt={1}>
        <Typography
          sx={{
            color: "commonColor.alert",
            cursor: "pointer",
            "&:hover": { color: "commonColor.alertHover" },
          }}
          onClick={() => navigate("/reauth")}
        >
          회원 탈퇴하기
        </Typography>
      </Box>
    </>
  );
};
