import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  PaymentType,
  netsTypes,
  useBookContext,
} from "../../../context/BookContext";
import { useEffect, useRef, useState } from "react";
import { CollapseMessage } from "../../../components/collapseMessage";
import dayjs from "dayjs";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { firestore } from "../../../firebase";
import { useAuth } from "../../../context/AuthContext";
import { timestampToDayjs } from "../../../components/dispatchFunctions";
import { Coupon, CouponWithRef } from "../../../model/coupon";
import { cloneDeep } from "lodash";
import { getTimeString, getToday } from "../../../components/function";
import { DialogYesNoAction } from "../../../components/dialog";
import { onRemoveBook } from "../../../apiCallFunctions/onRemoveBook";
import { onRenewBook } from "../../../apiCallFunctions/onRenewBook";
import { useResetTimer } from "../../../hooks/useResetTimer";
import { Loading } from "../../../components/loading";
import { PG_CARD, PG_VBANK, redirectUrl } from "../../../model/constants";

export const CheckReservation = () => {
  const navigate = useNavigate();

  const {
    isPC,
    state: { info },
  } = useAuth();

  const {
    testMode,
    book,
    book: { nets, bookDrive, bookManager, companion, guardian, paymentInfo },
    updateBook,
    updatePaymentInfo,
    updateBookManager,
  } = useBookContext();

  const [fee, setFee] = useState(paymentInfo.fee);
  const [couponCode, setCouponCode] = useState(paymentInfo.discount.couponCode);
  const [couponDescription, setCouponDescription] = useState(
    paymentInfo.discount.couponDescription
  );
  const [coupon, setCoupon] = useState(paymentInfo.discount.coupon);
  const couponRef = useRef<CouponWithRef>();
  const [couponMessage, setCouponMessage] = useState("");
  const [point, setPoint] = useState<number>(paymentInfo.discount.point);
  const [myPoint, setMyPoint] = useState(info.customer?.point || 0);
  const [paymentType, setPaymentType] = useState(paymentInfo.paymentType);
  const [loading, setLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string | undefined>(
    undefined
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const pgTimerId = useRef<NodeJS.Timer>();

  useEffect(() => {
    if (book.uid.bookUser === "") {
      navigate("/", { replace: true });
    }

    onRenew();

    if (!testMode) {
      return () => {
        if (pgTimerId.current) clearInterval(pgTimerId.current);
      };
    }
  }, []);

  useEffect(() => {
    if (!testMode) {
      onResetThrottle();
      onRenewThrottle();
    }
  }, [point, coupon, paymentType]);

  // PG 팝업이 떠있는 동안은 예약이 삭제되지 않도록 creationDate 시간 계속 갱신
  const startPgTimer = () => {
    // 기존 3분 timer 정지
    stopTimer();

    if (pgTimerId.current) clearInterval(pgTimerId.current);
    pgTimerId.current = setInterval(() => {
      onRenewThrottle();
    }, 1000);
  };

  // 결제 취소로 인해 PG 팝업이 닫힘
  const stopPgTimer = () => {
    // pg timer 정지
    if (pgTimerId.current) clearInterval(pgTimerId.current);
    // 3분 타이머 다시 시작
    resetTimer();
  };

  // creationDate 갱신. 2분마다 동작
  const onRenew = async () => {
    try {
      await onRenewBook([book.uid.id]);
    } catch (error) {
      console.log(error);
    }
  };

  // 타임아웃 시 임시예약 모두 삭제 후 페이지 이동
  const onClear = async (callByTimer: boolean = false) => {
    setClearLoading(true);

    try {
      await onRemoveBook([book.uid.id]);
    } catch (error) {
      console.log(error);
    }

    setClearLoading(false);
    navigate("/reservation", { replace: true, state: { callByTimer } });
  };

  const { timer, stopTimer, resetTimer, onResetThrottle, onRenewThrottle } =
    useResetTimer(onRenew, onClear);

  const onConfirmBook = async () => {
    // 0원으로  books-payment-verify에 넣고, functions에서 분기로 처리하자
    onStore();
  };

  const onStore = async () => {
    setOpenDialog(false);
    // 100원 미만 결제 불가능
    if (fee > 0 && fee < 100) {
      setAlertMessage(
        "PG결제 최소 금액제한으로 100원 미만은 결제하실 수 없습니다."
      );
      return;
    }

    setLoading(true);

    const bookForVerify = cloneDeep(book);

    await onRenew();
    // PG 팝업이 떠있는 동안은 예약이 삭제되지 않도록 creationDate 시간 계속 갱신
    startPgTimer();
    const merchant_uid = `nets_${dayjs()
      .locale("en")
      .format("YYMMDD_HHmmss_SSS")}`;
    const imp_uid = `imp_${dayjs().locale("en").format("YYMMDDHHmmssSSS")}`;

    if (fee === 0) {
      bookForVerify.paymentInfo.imp_uid = imp_uid;
    }
    bookForVerify.paymentInfo.merchant_uid = merchant_uid;
    bookForVerify.paymentInfo.fee = fee;

    // 이유를 알수 없지만 point가 string type으로 들어가서 오류가 발생함.
    // Number type으로 강제 치환함
    let pp = Number(point);

    bookForVerify.paymentInfo.paymentType = paymentType;
    bookForVerify.paymentInfo.discount = couponRef.current
      ? {
          couponCode,
          couponDescription,
          couponRef: couponRef.current.docRef,
          coupon,
          point: pp,
        }
      : {
          couponCode,
          couponDescription,
          coupon,
          point: pp,
        };
    bookForVerify.paymentInfo.bookState = "waiting";
    bookForVerify.paymentInfo.isPaid = fee === 0 ? "ok" : "waiting";

    await setDoc(
      doc(firestore, "books-payment-verify", merchant_uid),
      bookForVerify
    );

    if (fee === 0) {
      let param: {
        imp_uid: string;
        merchant_uid: string;
        imp_success: string;
        error_code?: string;
        error_msg?: string;
      } = {
        imp_uid: imp_uid,
        merchant_uid: merchant_uid,
        imp_success: "true",
      };

      navigate(
        { pathname: "../verify", search: `?${createSearchParams(param)}` },
        { replace: true }
      );
    } else {
      switch (paymentType) {
        case "kakaopay":
        case "card":
          onUseCard(merchant_uid);
          break;
        case "account":
          onUseVBank(merchant_uid);
          break;
        default:
          break;
      }
    }
  };

  const onUseVBank = (merchant_uid: string) => {
    const due = dayjs().add(1, "hour").format("YYYYMMDDHHmmss");
    const data = {
      // pg: "nice_v2.iamport03m", // PG사
      pg: PG_VBANK, // PG사
      pay_method: "vbank", // 결제수단
      merchant_uid: merchant_uid, // 주문번호
      name: netsTypes[nets.type].label, // 주문명
      // amount: 100, // 결제금액
      amount: fee, // 결제금액
      buyer_email: "", // 구매자 이메일
      buyer_name: guardian.name, // 구매자 이름
      buyer_tel: guardian.phone, // 구매자 전화번호
      buyer_addr: "",
      buyer_postcode: "",
      m_redirect_url: redirectUrl,
      vbank_due: due,
    };

    const { IMP }: any = window;
    IMP.init(process.env.REACT_APP_IMP_CODE);
    IMP.request_pay(data, onPaymentCallback);
  };

  const onUseCard = (merchant_uid: string) => {
    // payment
    if (!testMode) {
      // export const pgInfo: PGType[] = [{ label: "kakaopay", id: "TC0ONETIME"}, {label: "nice_v2", id:"iamport01m"}]
      const data = {
        pg: `${
          paymentType === "kakaopay" ? "kakaopay.TC0ONETIME" : PG_CARD
          // "nice_v2.iamport00m"
        }`, // PG사
        pay_method: `${paymentType === "kakaopay" ? "card" : paymentType}`, // 결제수단
        merchant_uid: merchant_uid,
        // merchant_uid: `nets_${dayjs().locale("en").format("YYYYMMDD_HHmmss")}`, // 주문번호
        name: netsTypes[nets.type].label, // 주문명
        // amount: 100, // 결제금액
        amount: fee, // 결제금액
        buyer_email: "", // 구매자 이메일
        buyer_name: guardian.name, // 구매자 이름
        buyer_tel: guardian.phone, // 구매자 전화번호
        buyer_addr: "",
        buyer_postcode: "",
        m_redirect_url: redirectUrl,
        // buyer_addr: '신사동 661-16', // 구매자 주소
      };

      const { IMP }: any = window;
      IMP.init(process.env.REACT_APP_IMP_CODE);
      IMP.request_pay(data, onPaymentCallback);
    } else {
      setTimeout(() => {
        onPaymentCallback({
          success: true,
          error_msg: "결제에 실패했습니다",
        });
      }, 100);
    }
  };

  const onPaymentCallback = async (response: any) => {
    stopPgTimer();

    if (response.error_msg === undefined) {
      let param: {
        imp_uid: string;
        merchant_uid: string;
        imp_success: string;
        error_code?: string;
        error_msg?: string;
      } = {
        imp_uid: response.imp_uid,
        merchant_uid: response.merchant_uid,
        imp_success: response.error_msg ? "false" : "true",
      };

      if (!param.imp_success) {
        param.error_code = response.error_code;
        param.error_msg = response.error_msg;
      }

      navigate(
        { pathname: "../verify", search: `?${createSearchParams(param)}` },
        { replace: true }
      );
    } else {
      const ref = doc(firestore, "books-payment-verify", response.merchant_uid);
      await deleteDoc(ref);

      setAlertMessage(response.error_msg);
      setLoading(false);
    }
  };

  const findCoupon = async (code: string) => {
    setFee(paymentInfo.totalFee - point);
    setCoupon(0);
    setCouponDescription("");
    couponRef.current = undefined;
    setCouponMessage("");
    try {
      const ref = collection(firestore, "coupon");
      const qry = query(
        ref,
        where("code", "==", code)
        // where("endDate", ">=", Timestamp.now())
      );
      const response = await getDocs(qry);

      if (response.size <= 0) {
        setCouponMessage("등록되지 않은 쿠폰 번호입니다");
      } else {
        for (let snapshot of response.docs) {
          if (snapshot.exists()) {
            const data = snapshot.data() as Coupon;
            let coupon: CouponWithRef = {
              ...data,
              docRef: snapshot.ref,
            };

            const now = getToday();
            const startDate = timestampToDayjs(coupon.startDate);
            const endDate = timestampToDayjs(coupon.endDate);

            const startError = now.isBefore(startDate, "day");
            const endError = endDate.isBefore(now, "day");

            if (startError || endError) {
              setCouponMessage("사용 기한이 아닙니다.");
            } else if (
              coupon.activation === 0 ||
              coupon.numOfUsed >= coupon.quantity
            ) {
              setCouponMessage("만료된 쿠폰입니다.");
            } else if (
              coupon.reusable !== 2 &&
              coupon.whoUsed.includes(info.uid)
            ) {
              setCouponMessage("이미 사용된 쿠폰입니다.");
            } else {
              let amount =
                coupon.type === 0
                  ? coupon.amount
                  : paymentInfo.totalFee * (coupon.amount / 100);

              // 쿠폰 금액이 총 결제금액보다 높은 경우
              if (paymentInfo.totalFee <= amount) {
                amount = paymentInfo.totalFee;
              }

              setCoupon(amount);
              setCouponDescription(coupon.description);
              couponRef.current = coupon;

              const fee = paymentInfo.totalFee - point - amount;
              if (fee < 0) {
                const newPoint = point + fee;
                setPoint(newPoint);
                setFee(0);
              } else setFee(fee);
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box p={1}>
      <Loading open={clearLoading} />
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
        <Typography color={"primary"}>예약 일정 및 결제 정보 확인</Typography>
        <Typography
          color={"commonColor.warning"}
          sx={
            timer < 60
              ? {
                  animation: "blink-effect 1.5s infinite",
                }
              : {}
          }
        >{`* ${getTimeString(
          timer
        )}  동안 아무 입력이 없으면 예약이 자동으로 취소됩니다.`}</Typography>
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box display={"flex"} gap={1} alignItems={"center"} mt={1}>
          <Typography width={"150px"}>배차일자</Typography>
          <Typography color={"primary"}>
            {dayjs(bookDrive.addrA.arrival.toDate()).format("ll (ddd)")}
          </Typography>
        </Box>
        <Typography sx={{ mt: 1 }}>서비스 시간</Typography>
        <Box
          sx={{
            mt: 1,
            py: 2,
            px: 2,
            borderRadius: 2,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "commonColor.main",
          }}
        >
          <Box display={"flex"} gap={2}>
            <Typography>픽업지 차량 도착</Typography>
            <Typography color={"primary"}>
              {dayjs(bookDrive.addrA.arrival.toDate()).format("LT")}
            </Typography>
          </Box>
          <Typography sx={{ mt: 0.5 }}>
            {bookDrive.addrA.addr}, {bookDrive.addrA.addrDetail}
          </Typography>
        </Box>

        <Box
          sx={{
            mt: 1,
            py: 2,
            px: 2,
            borderRadius: 2,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "commonColor.main",
          }}
        >
          <Box display={"flex"} gap={2}>
            <Typography>목적지 차량 도착</Typography>
            <Typography color={"primary"}>
              {dayjs(bookDrive.addrB.arrival.toDate()).format("LT")}
            </Typography>
          </Box>
          <Typography sx={{ mt: 0.5 }}>
            {bookDrive.addrB.addr}, {bookDrive.addrB.addrDetail}
          </Typography>
        </Box>

        {bookDrive.type === "round-trip" && (
          <Box
            sx={{
              mt: 1,
              py: 2,
              px: 2,
              borderRadius: 2,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "commonColor.main",
            }}
          >
            <Box display={"flex"} gap={2}>
              <Typography>목적지 차량 출발</Typography>
              <Typography color={"primary"}>
                {dayjs(bookDrive.addrB.arrival.toDate())
                  .add(companion.stayHour, "hour")
                  .add(companion.stayMin, "minute")
                  .format("LT")}
              </Typography>
            </Box>
            <Typography sx={{ mt: 0.5 }}>
              {`귀가지 : ${bookDrive.addrC.addr}, ${bookDrive.addrC.addrDetail}`}
            </Typography>
          </Box>
        )}
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography fontWeight={600}>서비스 금액</Typography>
          <Typography
            fontWeight={600}
          >{`${paymentInfo.totalFee.toLocaleString()}원`}</Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography>네츠 서비스</Typography>
          <Typography fontWeight={600}>
            {nets.netsFee.toLocaleString()}원
          </Typography>
        </Box>

        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography fontSize={isPC ? "0.9rem" : "0.8rem"} sx={{ ml: 2 }}>
            - {netsTypes[nets.type].label}
          </Typography>
          <Typography fontSize={isPC ? "0.9rem" : "0.8rem"} sx={{ ml: 2 }}>
            {`${(nets.type === "move"
              ? netsTypes[nets.type].fee
              : netsTypes[nets.type][bookDrive.type]
            ).toLocaleString()}원`}
          </Typography>
        </Box>
        {nets.type === "wheel-plus" && (
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box>
              <Typography fontSize={isPC ? "0.9rem" : "0.8rem"} sx={{ ml: 2 }}>
                {`- 계단 이동 : ${nets.floor.label} (+ ${nets.floor.value}) ${
                  isPC ? "(왕복시 x2)" : ""
                } `}
              </Typography>
              {!isPC && bookDrive.type === "round-trip" && (
                <Typography fontSize={"0.8rem"} ml={3}>
                  (왕복시 x2)
                </Typography>
              )}
            </Box>
            <Typography fontSize={isPC ? "0.9rem" : "0.8rem"}>
              {(bookDrive.type === "round-trip"
                ? nets.floor.value * 2
                : nets.floor.value
              ).toLocaleString()}
              원
            </Typography>
          </Box>
        )}
        {nets.bedwheel && (
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography fontSize={isPC ? "0.9rem" : "0.8rem"} sx={{ ml: 2 }}>
              - 침대형 거상 이용 (+ 11,000원)
            </Typography>
            <Typography fontSize={isPC ? "0.9rem" : "0.8rem"}>
              11,000원
            </Typography>
          </Box>
        )}

        {bookDrive.type === "round-trip" && companion.companionFee > 0 && (
          <Box display={"flex"} justifyContent={"space-between"} mt={1}>
            <Box>
              <Typography>
                목적지에 머무는 시간{" "}
                {isPC && (
                  <Typography component="span" fontSize={"0.8rem"}>
                    (1시간 무료, 추가 20분당 8,000원)
                  </Typography>
                )}
              </Typography>
              {!isPC && (
                <Typography fontSize={"0.7rem"} sx={{ ml: 1 }}>
                  (1시간 무료, 추가 20분당 8,000원)
                </Typography>
              )}
              <Typography fontSize={isPC ? "0.9rem" : "0.8rem"} sx={{ ml: 2 }}>
                {`- ${companion.stayHour}시간 ${companion.stayMin}분 `}
              </Typography>
            </Box>
            <Typography fontWeight={600}>
              {companion.companionFee.toLocaleString()}원
            </Typography>
          </Box>
        )}

        <Box display={"flex"} justifyContent={"space-between"} mt={1}>
          <Box>
            <Typography>
              {`총 이동거리: 
              ${(
                (bookDrive.addrA.distanceToNext +
                  bookDrive.addrB.distanceToNext) /
                1000
              ).toFixed(2)}km `}
              {isPC && (
                <Typography component="span" fontSize={"0.8rem"}>
                  (편도 기본 10km, 추가 5km당 11,000원)
                </Typography>
              )}
            </Typography>

            {!isPC && (
              <Typography fontSize={"0.7rem"} sx={{ ml: 1 }}>
                (편도당 10km 무료, 추가 5km당 11,000원)
              </Typography>
            )}
          </Box>
          <Typography fontWeight={600}>
            {(
              bookDrive.addrA.feeToNext + bookDrive.addrB.feeToNext
            ).toLocaleString()}
            원
          </Typography>
        </Box>
        {bookDrive.addrA.feeToNext + bookDrive.addrB.feeToNext > 0 && (
          <>
            {bookDrive.addrA.feeToNext > 0 && (
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography
                  fontSize={isPC ? "0.9rem" : "0.8rem"}
                  sx={{ ml: 2 }}
                >
                  {`- 픽업지 ➝ 목적지: ${(
                    bookDrive.addrA.distanceToNext / 1000
                  ).toFixed(2)}km`}
                </Typography>

                <Typography
                  fontSize={isPC ? "0.9rem" : "0.8rem"}
                  sx={{ ml: 2 }}
                >
                  {`${bookDrive.addrA.feeToNext.toLocaleString()}원`}
                </Typography>
              </Box>
            )}
            {bookDrive.addrB.feeToNext > 0 && (
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography
                  fontSize={isPC ? "0.9rem" : "0.8rem"}
                  sx={{ ml: 2 }}
                >
                  {`- 목적지 ➝ 귀가지: ${(
                    bookDrive.addrB.distanceToNext / 1000
                  ).toFixed(2)}km`}
                </Typography>

                <Typography
                  fontSize={isPC ? "0.9rem" : "0.8rem"}
                  sx={{ ml: 2 }}
                >
                  {`${bookDrive.addrB.feeToNext.toLocaleString()}원`}
                </Typography>
              </Box>
            )}

            {bookDrive.type === "round-trip" && bookDrive.sameReturn && (
              <Typography
                fontSize={isPC ? "0.9rem" : "0.8rem"}
                sx={{
                  ml: 2,
                  wordBreak: "keep-all",
                  color: "commonColor.warning",
                }}
              >
                * 왕복시 픽업지와 귀가지가 동일한 경우 짧은 이동거리에 맞춰
                계산합니다
              </Typography>
            )}
          </>
        )}

        {nets.surCharge && (
          <>
            <Box display={"flex"} justifyContent={"space-between"} mt={1}>
              <Typography>할증 요금 (30%)</Typography>
              <Typography fontWeight={600}>
                {(
                  ((paymentInfo.totalFee - (nets.bedwheel ? 11000 : 0)) / 1.3) *
                  0.3
                ).toLocaleString()}
                원
              </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography fontSize={isPC ? "0.9rem" : "0.8rem"} sx={{ ml: 2 }}>
                - {netsTypes[nets.type].label}
              </Typography>
              <Typography fontSize={isPC ? "0.9rem" : "0.8rem"} sx={{ ml: 2 }}>
                {`${(
                  (nets.type === "move"
                    ? netsTypes[nets.type].fee
                    : netsTypes[nets.type][bookDrive.type]) * 0.3
                ).toLocaleString()}
            원`}
              </Typography>
            </Box>
            {nets.type === "wheel-plus" && (
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box>
                  <Typography
                    fontSize={isPC ? "0.9rem" : "0.8rem"}
                    sx={{ ml: 2 }}
                  >
                    {`- 계단 이동 : ${nets.floor.label} (+ ${
                      nets.floor.value
                    }) ${isPC ? "(왕복시 x2)" : ""} `}
                  </Typography>
                  {!isPC && bookDrive.type === "round-trip" && (
                    <Typography fontSize={"0.8rem"} ml={3}>
                      (왕복시 x2)
                    </Typography>
                  )}
                </Box>
                <Typography fontSize={isPC ? "0.9rem" : "0.8rem"}>
                  {(
                    (bookDrive.type === "round-trip"
                      ? nets.floor.value * 2
                      : nets.floor.value) * 0.3
                  ).toLocaleString()}
                  원
                </Typography>
              </Box>
            )}
            {bookDrive.type === "round-trip" && (
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography
                  fontSize={isPC ? "0.9rem" : "0.8rem"}
                  sx={{ ml: 2 }}
                >
                  {`- 목적지에 머무는 시간 : ${companion.stayHour}시간 ${companion.stayMin}분`}
                </Typography>
                <Typography fontSize={isPC ? "0.9rem" : "0.8rem"}>
                  {(companion.companionFee * 0.3).toLocaleString()}원
                </Typography>
              </Box>
            )}

            {bookDrive.type === "round-trip" ? (
              bookDrive.addrA.feeToNext + bookDrive.addrB.feeToNext > 0 ? (
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Box>
                    <Typography
                      fontSize={isPC ? "0.9rem" : "0.8rem"}
                      sx={{ ml: 2 }}
                    >
                      {isPC
                        ? `- 이동거리 추가요금 : ${(
                            bookDrive.addrA.distanceToNext / 1000
                          ).toFixed(2)}km + ${(
                            bookDrive.addrB.distanceToNext / 1000
                          ).toFixed(2)}km`
                        : "- 이동거리 추가요금 : "}
                    </Typography>
                    {!isPC && (
                      <Typography fontSize={"0.8rem"} ml={3}>{`${(
                        bookDrive.addrA.distanceToNext / 1000
                      ).toFixed(2)}km + ${(
                        bookDrive.addrB.distanceToNext / 1000
                      ).toFixed(2)}km`}</Typography>
                    )}
                  </Box>
                  <Typography fontSize={isPC ? "0.9rem" : "0.8rem"}>
                    {(
                      (bookDrive.addrA.feeToNext + bookDrive.addrB.feeToNext) *
                      0.3
                    ).toLocaleString()}
                    원
                  </Typography>
                </Box>
              ) : (
                <></>
              )
            ) : bookDrive.addrA.feeToNext > 0 ? (
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography
                  fontSize={isPC ? "0.9rem" : "0.8rem"}
                  sx={{ ml: 2 }}
                >
                  {`- 이동거리 추가요금 : ${(
                    bookDrive.addrA.distanceToNext / 1000
                  ).toFixed(2)}km`}
                </Typography>
                <Typography fontSize={isPC ? "0.9rem" : "0.8rem"}>
                  {(bookDrive.addrA.feeToNext * 0.3).toLocaleString()}원
                </Typography>
              </Box>
            ) : (
              <></>
            )}
          </>
        )}
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box
          display={"flex"}
          gap={2}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography>쿠폰 등록</Typography>
          <Box display={"flex"} gap={2}>
            <TextField
              sx={{ width: "150px" }}
              placeholder="쿠폰번호 입력"
              variant="outlined"
              value={couponCode}
              onChange={(event) => setCouponCode(event.target.value)}
              size="small"
            />
            {coupon !== 0 ? (
              <Button
                color="error"
                variant="contained"
                onClick={() => {
                  setCoupon(0);
                  setCouponCode("");
                  setCouponDescription("");
                  setCouponMessage("");
                  couponRef.current = undefined;
                  setFee(paymentInfo.totalFee - point);
                }}
              >
                등록 취소
              </Button>
            ) : (
              <Button
                disabled={couponCode.length !== 10}
                variant="contained"
                onClick={() => findCoupon(couponCode)}
              >
                쿠폰 확인
              </Button>
            )}
          </Box>
        </Box>
        {coupon > 0 && (
          <Box
            display={"flex"}
            gap={2}
            alignItems={"center"}
            justifyContent={"space-between"}
            mt={1}
            sx={{
              "& > p": {
                color: "commonColor.grey",
              },
            }}
          >
            <Typography>{couponDescription}</Typography>
            <Typography>{coupon.toLocaleString()}원</Typography>
          </Box>
        )}
        {couponMessage !== "" && (
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            mt={1}
            sx={{
              "& > p": {
                color: "commonColor.warning",
              },
            }}
          >
            <Typography>{couponMessage}</Typography>
          </Box>
        )}
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box
          display={"flex"}
          gap={2}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography>포인트 사용</Typography>
          <Box display={"flex"} gap={2} alignItems={"center"}>
            <TextField
              sx={{ width: "150px" }}
              value={point.toLocaleString()}
              onChange={(event) => {
                const p =
                  event.target.value === ""
                    ? 0
                    : parseInt(
                        (event.target.value as string).replace(/\D/g, "")
                      );
                let newPoint = 0;
                const currentFee = paymentInfo.totalFee - coupon;

                // 잔여포인트가 결제금액보다 큼. 사용 포인트 신경 안써도 됨
                if (myPoint >= currentFee) {
                  // 입력한 숫자가 결제 금액 이하
                  if (p < currentFee) {
                    // 포인트를 써도 0원이 안될 경우 결제금액 100원까지만 포인트 사용
                    newPoint = currentFee - p >= 100 ? p : currentFee - 100;
                  } else {
                    // 결제금액을 0원으로 만듬
                    newPoint = currentFee;
                  }

                  // 잔여포인트가 결제금액보다 작음. (다 사용해도 결제금액이 0원이 되지 않음)
                } else {
                  // 잔여포인트를 다 써도 결제금액이 100원 이상 남을 경우
                  if (currentFee - myPoint >= 100) {
                    newPoint = myPoint >= p ? p : myPoint;
                    // 잔여포인트를 다 쓰면 100원 미만이 되는 경우
                  } else {
                    newPoint = currentFee - p >= 100 ? p : currentFee - 100;
                  }
                }

                setPoint(newPoint);
                setFee(paymentInfo.totalFee - newPoint - coupon);
              }}
              variant="outlined"
              size="small"
              inputProps={{ style: { textAlign: "end" } }}
            />
            <Button
              variant="contained"
              onClick={() => {
                const currentFee = paymentInfo.totalFee - coupon;
                let newPoint = 0;

                if (myPoint <= 0) {
                  newPoint = 0;
                } else {
                  // 잔여포인트가 결제금액보다 큼. 사용 포인트 신경 안써도 됨
                  if (myPoint >= currentFee) {
                    newPoint = currentFee;

                    // 잔여포인트가 결제금액보다 작음. (다 사용해도 결제금액이 0원이 되지 않음)
                  } else {
                    // 잔여포인트를 다 써도 결제금액이 100원 이상 남을 경우
                    if (currentFee - myPoint >= 100) {
                      newPoint = myPoint;
                      // 잔여포인트를 다 쓰면 100원 미만이 되는 경우
                    } else {
                      newPoint = currentFee - 100;
                    }
                  }
                }

                setPoint(newPoint);
                setFee(paymentInfo.totalFee - newPoint - coupon);
              }}
            >
              전액 사용
            </Button>
          </Box>
        </Box>
        <Box
          display={"flex"}
          gap={2}
          alignItems={"center"}
          justifyContent={"space-between"}
          mt={1}
          sx={{
            "& > p": {
              color: "commonColor.grey",
            },
          }}
        >
          <Typography>사용 가능 포인트</Typography>
          <Typography>{(myPoint - point).toLocaleString()}포인트</Typography>
        </Box>
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box
          display={"flex"}
          gap={2}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography>총 결제금액</Typography>
          <Typography color={"primary"}>
            {fee.toLocaleString()}원
            {/* <Typography component={"span"} color={"commonColor.alert"}>
              (0원)
            </Typography> */}
          </Typography>
        </Box>
        {/* <Typography color={"commonColor.alert"} align="right">
          테스트를 위해 실제 결제 단계를 생략합니다.
        </Typography> */}
        {/* <Typography color={"commonColor.alert"} align="right">
          결제된 금액은 당일 자정 근처에 자동으로 환불됩니다.
        </Typography> */}
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Typography>결제 방법 선택</Typography>
        <FormControl sx={{ pl: 1, pt: 1 }}>
          <RadioGroup
            value={paymentType}
            onChange={(_, value) =>
              setPaymentType(value as unknown as PaymentType)
            }
          >
            {/* <FormControlLabel
              value="kakaopay"
              control={<Radio size="small" />}
              label={
                <Box display={"flex"} height={"24px"} gap={1}>
                  <img src="/images/kakaopay_icon.png" alt="kakaopay" />
                  카카오페이
                </Box>
              }
            /> */}
            <FormControlLabel
              value="card"
              control={<Radio size="small" />}
              label="카드 결제"
            />
            <FormControlLabel
              value="account"
              control={<Radio size="small" />}
              label="가상 계좌"
            />
          </RadioGroup>
        </FormControl>
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Typography
          color={"commonColor.warning"}
          sx={
            timer < 60
              ? {
                  animation: "blink-effect 1.5s infinite",
                }
              : {}
          }
        >{`* ${getTimeString(
          timer
        )}  동안 아무 입력이 없으면 예약이 자동으로 취소됩니다.`}</Typography>
      </Paper>

      <CollapseMessage
        type="error"
        message={alertMessage || ""}
        open={alertMessage !== undefined}
        onClose={() => setAlertMessage(undefined)}
        sx={{ mt: 2 }}
      />

      <DialogYesNoAction
        title={"예약 확정"}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onAgree={onConfirmBook}
      >
        <Typography>결제하실 금액이 없습니다.</Typography>
        <Typography>예약을 확정하시겠습니까?</Typography>
      </DialogYesNoAction>

      <DialogYesNoAction
        title={"임시예약 취소"}
        color="alert"
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        onAgree={() => {
          setOpenCancelDialog(false);
          onClear(false);
        }}
      >
        <Typography>작성중이던 예약을 취소하시겠습니까?</Typography>
      </DialogYesNoAction>
      <Box flex={1}>
        <Button
          variant="contained"
          fullWidth
          disabled={loading || clearLoading}
          sx={{
            mt: 1,
            mb: loading ? 0 : 1,
            fontWeight: "600",
            fontSize: "1.1rem",
          }}
          onClick={() => (fee === 0 ? setOpenDialog(true) : onStore())}
          // onClick={() => setLoading(true)}
        >
          {loading ? "결제중" : "결제하기"}
        </Button>
        {loading && (
          <LinearProgress sx={{ mt: -0.5, mb: 1, borderRadius: 24 }} />
        )}
      </Box>

      <Box display={"flex"} width={"100%"} gap={1} mt={0}>
        <Button
          variant="contained"
          color="error"
          disabled={loading || clearLoading}
          sx={{ fontSize: "1.1rem", flex: 1 }}
          onClick={() => setOpenCancelDialog(true)}
        >
          {`예약 취소`}
        </Button>
        <Button
          variant="contained"
          color="warning"
          disabled={loading || clearLoading}
          sx={{ fontSize: "1.1rem", flex: 2 }}
          onClick={() => navigate(-1)}
        >
          {`수정하기`}
        </Button>
      </Box>
    </Box>
  );
};
