import { Box, Typography } from "@mui/material";
import { useAuth } from "../context/AuthContext";
import { firestore } from "../firebase";
import { Ref, forwardRef, useRef, useState } from "react";
import { DialogNoAction } from "./dialog";
import { doc, getDoc } from "firebase/firestore";
import { BoardInfo } from "../context/BoardContext";

export const Footer = forwardRef((_, ref: Ref<HTMLDivElement>) => {
  const { isPC, isXSMobile } = useAuth();

  const [showTermsOfUse, setShowTermsOfUse] = useState(false);
  const termsOfUse = useRef<string>("");
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const privacyPolicy = useRef<string>("");

  const onShowTermsOfUse = async () => {
    if (termsOfUse.current !== "") {
      setShowTermsOfUse(true);
    } else {
      const res = await getDoc(doc(firestore, "boards-notice", "termsOfUse"));
      if (res.exists()) {
        const data = res.data() as BoardInfo;
        termsOfUse.current = data.description;
        setShowTermsOfUse(true);
      }
    }
  };

  const onShowPrivatePolicy = async () => {
    if (privacyPolicy.current !== "") {
      setShowPrivacyPolicy(true);
    } else {
      const res = await getDoc(
        doc(firestore, "boards-notice", "privatePolicy")
      );
      if (res.exists()) {
        const data = res.data() as BoardInfo;
        privacyPolicy.current = data.description;
        setShowPrivacyPolicy(true);
      }
    }
  };

  return (
    <>
      <Box
        ref={ref}
        width={"100%"}
        bgcolor={"#f8f9fa"}
        justifyContent={"center"}
        mt={2}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          py={4}
          gap={isPC ? 1 : 0}
          sx={{
            textDecoration: "none",
            color: "#878890",
            "& > p, & > div > p, & > div > a": {
              fontSize: isXSMobile ? "0.7rem" : "0.8rem",
            },
            "& > a, & > div > a": {
              ":link": { color: "white" },
              ":visited": { color: "white" },
              ":hover": { color: "commonColor.main" },
              ":active": { color: "#1e7589" },
            },
          }}
        >
          {isPC ? (
            <>
              <Box display={"flex"} gap={1}>
                <Typography
                  fontWeight={"700"}
                  onClick={() => onShowTermsOfUse()}
                  sx={{ cursor: "pointer" }}
                >
                  이용약관
                </Typography>
                <Typography>|</Typography>
                <Typography
                  fontWeight={"700"}
                  onClick={() => onShowPrivatePolicy()}
                  sx={{ cursor: "pointer" }}
                >
                  개인정보처리방침
                </Typography>
              </Box>
              <Box display={"flex"} gap={1}>
                <Typography>대표 김원종</Typography>
                <Typography>|</Typography>
                <Typography>사업자등록번호 : 276-81-01736</Typography>
                <Typography>|</Typography>
                <Typography>
                  통신판매업 신고번호 : 2021-서울송파-0947호
                </Typography>
              </Box>
              <Box display={"flex"} gap={1}>
                <Typography>전화번호 : 070-5088-2000</Typography>
                <Typography>|</Typography>
                <Typography>이메일 : netsmobility@gmail.com</Typography>
              </Box>
              <Box display={"flex"} gap={1}>
                <Typography>
                  서울시 성북구 안암로 145 고려대학교 자연계캠퍼스 산학관 525호
                </Typography>
              </Box>
              <Box display={"flex"} gap={1}>
                <Typography color={"#666666"} fontWeight={"700"}>
                  ⓒ 주식회사 네츠모빌리티 NetsMobility All rights reserved.
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Box display={"flex"} gap={1}>
                <Typography fontWeight={"700"}>이용약관</Typography>
                <Typography>|</Typography>
                <Typography fontWeight={"700"}>개인정보처리방침</Typography>
              </Box>
              <Box display={"flex"} gap={1}>
                <Typography>대표 김원종</Typography>
                <Typography>|</Typography>
                <Typography>사업자등록번호 : 276-81-01736</Typography>
              </Box>
              <Box display={"flex"} gap={1}>
                <Typography>
                  통신판매업 신고번호 : 2021-서울송파-0947호
                </Typography>
              </Box>

              <Box display={"flex"} gap={1}>
                <Typography>전화번호 : 070-5088-2000</Typography>
                <Typography>|</Typography>
                <Typography>이메일 : netsmobility@gmail.com</Typography>
              </Box>
              <Box display={"flex"} gap={1}>
                <Typography>
                  서울시 성북구 안암로 145 고려대학교 자연계캠퍼스 산학관 525호
                </Typography>
              </Box>
              <Box display={"flex"} gap={1}>
                <Typography color={"#666666"} fontWeight={"700"}>
                  ⓒ 주식회사 네츠모빌리티 NetsMobility All rights reserved.
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>

      <DialogNoAction
        open={showTermsOfUse}
        onClose={() => setShowTermsOfUse(false)}
        title="네츠모빌리티 이용약관"
      >
        <Typography
          dangerouslySetInnerHTML={{ __html: termsOfUse.current }}
          sx={{ "& > figure > img": { width: "100%" } }}
        />
      </DialogNoAction>

      <DialogNoAction
        open={showPrivacyPolicy}
        onClose={() => setShowPrivacyPolicy(false)}
        title="네츠모빌리티 개인정보 처리방침"
      >
        <Typography
          dangerouslySetInnerHTML={{ __html: privacyPolicy.current }}
          sx={{ "& > figure > img": { width: "100%" } }}
        />
      </DialogNoAction>
    </>
  );
});
