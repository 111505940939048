import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { Loading } from "../components/loading";
import { User } from "firebase/auth";
import { authService, firebaseSignOut, firestore } from "../firebase";
import { useMediaQuery } from "react-responsive";
import { Timestamp, doc, onSnapshot } from "firebase/firestore";
import { UserInfo } from "../model/userTypes";

// 모든 페이지에서 전역 변수처럼 사용할 수 있는 상태정보
type State = {
  auth: User | null | undefined;
  state:
    | "loading"
    | "signed-out"
    | "email-verifying"
    | "permission-denied"
    | "signed-in";
  info: UserInfo;
};

type ContextType = {
  isPC: boolean;
  isXSMobile: boolean;
  state: State;
  signedIn: (auth: User) => void;
  signOut: () => void;
};

type ActionType =
  | { type: "SIGNED-OUT" }
  | { type: "PERMISISON-DENIED" }
  | { type: "EMAIL-VERIFYING"; auth: User }
  | { type: "SIGNING-IN"; auth: User }
  | { type: "SIGNED-IN"; auth: User; info: UserInfo };

const initState: State = {
  auth: undefined,
  state: "loading",
  info: {
    uid: "",
    type: "customer",
    regDate: Timestamp.now(),
    customer: {
      uid: "",
      name: "신규고객",
      email: "",
      phone: "",
      point: 0,
      pointGets: 0,
      pointUsed: 0,
      cashReceipts: "",
    },
  },
};

const AuthContext = createContext<ContextType>({
  isPC: true,
  isXSMobile: false,
  state: initState,
  signedIn: () => {},
  signOut: () => {},
});

// 호출된 dispatch type에 따라 auth 정보가 업데이트됨
const reducer = (state: State, action: ActionType): State => {
  switch (action.type) {
    case "SIGNED-OUT":
      return { ...state, state: "signed-out" };
    case "EMAIL-VERIFYING":
      return { ...state, state: "email-verifying", auth: action.auth };
    case "PERMISISON-DENIED":
      return { ...initState, state: "permission-denied" };

    case "SIGNING-IN":
      return { ...state, auth: action.auth };
    case "SIGNED-IN":
      return {
        ...state,
        state: "signed-in",
        auth: action.auth,
        info: action.info,
      };

    default:
      return state;
  }
};

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const isPC = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const isXSMobile = useMediaQuery({
    query: "(max-width: 399px)",
  });

  const [state, dispatch] = useReducer(reducer, initState);

  // firebase 연결 상태 확인
  useEffect(() => {
    const unsubscribe = authService.onAuthStateChanged((auth) => {
      if (auth) {
        sessionStorage.setItem("uid", auth.uid);
        dispatch({ type: "SIGNING-IN", auth });
        // if (!auth.emailVerified) dispatch({ type: "EMAIL-VERIFYING", auth });
        // else dispatch({ type: "SIGNED-IN", auth });
      } else {
        dispatch({ type: "SIGNED-OUT" });
        sessionStorage.removeItem("uid");
      }
    });

    return () => {
      unsubscribe();
      // signedOut();
    };
  }, []);

  useEffect(() => {
    if (state.auth === undefined || state.auth === null) return;

    const unsubscribe = onSnapshot(
      doc(firestore, "user-info", state.auth.uid),
      (document) => {
        if (document.exists()) {
          const data = document.data() as UserInfo;
          if (data && data.type === "customer") {
            dispatch({
              type: "SIGNED-IN",
              auth: state.auth!,
              info: data,
            });
          } else if (data && data.type === "customer-leave") {
            dispatch({ type: "SIGNED-OUT" });
          } else {
            dispatch({ type: "PERMISISON-DENIED" });
          }
        }
      }
    );

    return () => {
      unsubscribe();
    };
  }, [state.auth, state.state]);

  // reducer 호출 함수
  const signedIn = (auth: User) => {
    dispatch({ type: "SIGNING-IN", auth });
  };

  const signOut = () => {
    firebaseSignOut();
  };

  // console.log(state);

  // 첫 접속, 새로고침 시 우선 loading 화면 보여줌
  if (state.state === "loading") return <Loading open={true} />;

  return (
    <AuthContext.Provider
      value={{ isPC, isXSMobile, state: state, signedIn, signOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};
