import { getDoc, doc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { BoardInfo } from "./context/BoardContext";
import { firestore } from "./firebase";
import { Box, Typography } from "@mui/material";

export const PrivacyPolicyPage = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState("");

  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  const getPrivacyPolicy = async () => {
    const res = await getDoc(doc(firestore, "boards-notice", "privatePolicy"));
    if (res.exists()) {
      const data = res.data() as BoardInfo;
      setPrivacyPolicy(data.description);
    }
  };

  return (
    <Box px={2}>
      <Typography mt={2} mb={4} fontWeight={600} fontSize={"1.2rem"}>
        네츠모빌리티 개인정보 처리방침
      </Typography>
      <Typography
        dangerouslySetInnerHTML={{ __html: privacyPolicy }}
        sx={{ "& > figure > img": { width: "100%" } }}
      />
    </Box>
  );
};
