import axios from "axios";
import { urls } from "../model/constants";

export const onPostponeBook = async (uid: string) => {
  return new Promise<void>((resolve, reject) => {
    axios({
      url: urls.onPostponeBook,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        uid,
      },
    })
      .then((result) => {
        if (result.status === 200) {
          const data = result.data;
          if (data.status !== "success") throw "error";

          resolve();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
