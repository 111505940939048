import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import { timestampToDayjs } from "../../components/dispatchFunctions";
import { useAuth } from "../../context/AuthContext";
import { useBoardContext } from "../../context/BoardContext";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { doc, runTransaction } from "firebase/firestore";
import { firestore } from "../../firebase";
import { FirebaseError } from "firebase/app";
import { CollapseMessage } from "../../components/collapseMessage";
import { DialogYesNoAction } from "../../components/dialog";

export const VocReadPage = () => {
  const navigate = useNavigate();
  const {
    isPC,
    state: { info },
  } = useAuth();
  const {
    board: { lists, index },
    updateIndex,
  } = useBoardContext();

  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState<string>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [index]);

  const onModify = () => {
    navigate("../write");
  };

  const onRemove = () => {
    runTransaction(firestore, async (transaction) => {
      transaction.delete(doc(firestore, "boards-voc", lists[index].key));

      if (lists[index].visible === "비공개") {
        transaction.delete(doc(firestore, "voc-waiting", lists[index].key));
      }
    })
      .then(() => {
        navigate(-1);
      })
      .catch((error: FirebaseError) => {
        console.log(error);
        setMessage(error.message);
      });
  };

  return (
    <>
      {index !== -1 && (
        <>
          <Paper elevation={2}>
            {isPC ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    py: 3,
                    pr: 4,
                    pl: 2,
                  }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <Box display={"flex"} gap={2}>
                    <ArrowBackIosNewIcon />
                    <Typography fontWeight={600} fontSize={"1.1rem"}>
                      {lists[index].title}
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    gap={2}
                    sx={{ "& > p": { fontSize: "0.9rem" } }}
                  >
                    <Typography>{lists[index].writer}</Typography>
                    <Typography>|</Typography>
                    <Typography>
                      {timestampToDayjs(lists[index].date).format("YYYY.MM.DD")}
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ mx: 1 }} />
                {lists[index].writerUid === info.uid && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    gap={2}
                    mt={2}
                    mr={2}
                  >
                    <Button variant="outlined" onClick={() => onModify()}>
                      수정하기
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "commonColor.alert" }}
                      onClick={() => setOpenDialog(true)}
                    >
                      삭제하기
                    </Button>
                  </Box>
                )}
                <Box px={4} py={2}>
                  {lists[index].visible === "비공개" && (
                    <Typography
                      fontWeight={600}
                      fontSize={"0.8rem"}
                      sx={{ color: "commonColor.warning" }}
                    >
                      관리자 확인 대기중입니다. 게시글은 스팸이 아닌 경우 내용
                      수정없이 공개 처리 됩니다.
                    </Typography>
                  )}
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: lists[index].description,
                    }}
                    sx={{ "& > figure > img": { width: "100%" } }}
                  ></Typography>
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    cursor: "pointer",
                    py: 3,
                    pr: 4,
                    pl: 2,
                  }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <Box display="flex" alignItems={"center"} gap={2}>
                    <ArrowBackIosNewIcon />
                    <Box>
                      <Typography fontWeight={600} fontSize={"1.1rem"}>
                        {lists[index].title}
                      </Typography>
                      <Box
                        display={"flex"}
                        gap={2}
                        mt={1}
                        sx={{ "& > p": { fontSize: "0.9rem" } }}
                      >
                        <Typography>{lists[index].writer}</Typography>
                        <Typography>|</Typography>
                        <Typography>
                          {timestampToDayjs(lists[index].date).format(
                            "YYYY.MM.DD"
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Divider sx={{ mx: 1 }} />
                {lists[index].writerUid === info.uid && (
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    gap={2}
                    mt={2}
                    mr={2}
                  >
                    <Button variant="outlined" onClick={() => onModify()}>
                      수정하기
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "commonColor.alert" }}
                      onClick={() => setOpenDialog(true)}
                    >
                      삭제하기
                    </Button>
                  </Box>
                )}
                <Box px={4} py={2}>
                  {lists[index].visible === "비공개" && (
                    <Typography
                      fontWeight={600}
                      fontSize={"0.8rem"}
                      sx={{ color: "commonColor.warning" }}
                    >
                      관리자 확인 대기중입니다. 게시글은 스팸이 아닌 경우 내용
                      수정없이 공개 처리 됩니다.
                    </Typography>
                  )}
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: lists[index].description,
                    }}
                    sx={{ "& > figure > img": { width: "100%" } }}
                  ></Typography>
                </Box>
              </>
            )}
          </Paper>

          <CollapseMessage
            type="info"
            open={message !== undefined}
            message={message || ""}
            onClose={() => setMessage(undefined)}
            sx={{ mt: 2, mb: 1 }}
          />

          <DialogYesNoAction
            title={"게시글 작성"}
            open={openDialog}
            color="alert"
            onClose={() => setOpenDialog(false)}
            onAgree={onRemove}
          >
            <Typography>해당 글을 삭제하시겠습니까?</Typography>
            <Typography>삭제된 게시글은 되살릴 수 없습니다</Typography>
          </DialogYesNoAction>

          <Box display={"flex"} justifyContent={"flex-end"} gap={2} mt={2}>
            <Button
              variant="outlined"
              disabled={index + 1 >= lists.length}
              onClick={() => updateIndex(index + 1)}
            >
              이전 글
            </Button>
            <Button
              variant="outlined"
              disabled={index <= 0}
              onClick={() => updateIndex(index - 1)}
            >
              다음 글
            </Button>
            <Button variant="contained" onClick={() => navigate(-1)}>
              목록 보기
            </Button>
          </Box>
        </>
      )}
    </>
  );
};
