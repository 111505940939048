import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { PaymentCancelType, useBookContext } from "../../context/BookContext";
import { useEffect, useState } from "react";
import { Timestamp, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import { CollapseMessage } from "../../components/collapseMessage";
import dayjs from "dayjs";
import { useAuth } from "../../context/AuthContext";
import { DialogYesNoAction } from "../../components/dialog";
import { bankCodesLabelValue } from "../../model/bankCode";
import { cloneDeep } from "lodash";
import { Loading } from "../../components/loading";
import { RefundType } from "../../model/refundType";
import { onGetCancelFee } from "../../apiCallFunctions/onGetCancelFee";
import { onPaymentCancel } from "../../apiCallFunctions/onPaymentCancel";
import { getToday } from "../../components/function";

export const ManageBookCancelPage = () => {
  const navigate = useNavigate();
  const {
    isPC,
    state: { info },
  } = useAuth();
  const { additionalAmount }: { additionalAmount: number } =
    useLocation().state;
  // const book: BookState | undefined = state?.book || undefined;
  const { book, updatePaymentInfo } = useBookContext();
  const [reason, setReason] = useState("");
  const [refundInfo, setRefundInfo] = useState<RefundType>({
    amount: 0,
    amountPoint: 0,
    percent: 100,
    percentReason: "",
    charge: 0,
    additionalAmount: 0,
    additionalAmountOrigin: 0,
  });

  const [bank, setBank] = useState("004");
  const [bankHolder, setBankHolder] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  // 환불 금액 계산
  // 1)예약 후 24시간 이내 혹은 서비스 날짜 기준 11일전 : 예약금의 100% 환불
  // 2)기본수수료 10,000원을 차감한 후 아래 기간에 따른 잔액 환불 비율을 적용하여 환불
  // 서비스날짜기준 10~7일전 : 잔액의70%환불
  // 서비스날짜기준 2~6일전 : 잔액의60%환불
  // 서비스날짜기준 48시간이내 : 0%
  // No-show (탑승예약시각30분초과시)로인한 취소 : 0%

  useEffect(() => {
    getCancelFee();
  }, [book]);

  const getCancelFee = () => {
    setLoading(true);
    onGetCancelFee(book.uid.id)
      .then((result) => {
        setRefundInfo(result);
        console.log(result);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const onCancel = async () => {
    setOpenDialog(false);
    setLoading(true);
    if (!book) {
      setLoading(false);
      return;
    }

    if (reason.length <= 0) {
      setLoading(false);
      setErrorMessage("취소 사유를 입력해주세요");
      return;
    }

    if (
      refundInfo.amount > 0 &&
      (book.paymentInfo.paymentType === "account" ||
        book.paymentInfo.paymentType === "accountTransfer")
    ) {
      if (bankHolder === "") {
        setLoading(false);
        setErrorMessage("예금주를 입력해주세요");
        return;
      }
      if (bankAccount === "") {
        setLoading(false);
        setErrorMessage("계좌번호를 입력해주세요");
        return;
      }
    }

    try {
      let paymentCancelData: PaymentCancelType = {
        bookUid: book.uid.id,
        userUid: info.uid,
        name: info.customer!.name,
        refundPercent: refundInfo.percent,
        refundAmount: refundInfo.amount,
        refundPoint: refundInfo.amountPoint,
        refundType: book.paymentInfo.paymentType,
        refundBank: bank,
        refundBankAccount: bankAccount,
        refundBankHolder: bankHolder,
        reason,
        creationDate: Timestamp.now(),
      };

      if (book.paymentInfo.discount.couponRef) {
        paymentCancelData.refundCoupon = book.paymentInfo.discount.couponRef;
      }

      const updateBook = cloneDeep(book);
      updateBook.paymentInfo.bookState = "cancel-request";
      updateBook.paymentInfo.isPaid = "refund-request";
      updateBook.isCancelled = true;

      if (updateBook.paymentInfo.imp_uid === "") {
        const imp_uid = `imp_${getToday().format("YYMMDDHHmmssSSS")}#${
          updateBook.uid.id
        }`;
        updateBook.paymentInfo.imp_uid = imp_uid;
      }

      await setDoc(
        doc(firestore, "books-payment-cancel", updateBook.paymentInfo.imp_uid),
        { ...paymentCancelData, creationDate: serverTimestamp() }
      );
      await onPaymentCancel(updateBook.paymentInfo.imp_uid, refundInfo.amount)
        .then(() => {
          const paymentInfo = updateBook.paymentInfo;
          updatePaymentInfo(paymentInfo, book.uid);

          if (updateBook.paymentInfo.paymentType === "accountTransfer") {
            navigate("/manageBooks/cancelWaiting", { replace: true });
          } else {
            navigate("/manageBooks/cancelled", { replace: true });
          }
        })
        .catch((error) => {
          setErrorMessage("서버 오류");
          console.log(error);
        });
    } catch (e) {
      setErrorMessage("입력값 오류");
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {book && (
        <>
          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
            <Typography color={"primary"}>
              {dayjs(book.uid.date, "YYMMDD").format("YYYY.MM.DD")} 예약을
              취소합니다.
            </Typography>
          </Paper>

          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
            <Typography>총 금액</Typography>
            <Typography>
              {book.paymentInfo.totalFee.toLocaleString()}원
            </Typography>
          </Paper>

          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
            <Typography>쿠폰 할인</Typography>
            <Typography>
              {book.paymentInfo.discount.coupon.toLocaleString()}원
            </Typography>
          </Paper>

          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
            <Typography>사용한 포인트</Typography>
            <Typography>
              {book.paymentInfo.discount.point.toLocaleString()}원
            </Typography>
          </Paper>

          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
            <Typography>실 결제 금액</Typography>
            <Typography>{book.paymentInfo.fee.toLocaleString()}원</Typography>
          </Paper>

          {refundInfo.additionalAmountOrigin !== 0 && (
            <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
              <Typography>추가 결제/환불</Typography>
              <Typography>
                {refundInfo.additionalAmountOrigin.toLocaleString()}원
              </Typography>
            </Paper>
          )}

          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
            <Typography>취소 수수료</Typography>
            <Typography>{`${refundInfo.charge.toLocaleString()}원`}</Typography>

            <Typography sx={{ mt: 1 }}>환불 비율</Typography>
            <Typography>{`${refundInfo.percent}% (${refundInfo.percentReason})`}</Typography>
          </Paper>

          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
            <Typography>반환 포인트</Typography>
            <Typography>{`${refundInfo.amountPoint.toLocaleString()}pt`}</Typography>
          </Paper>

          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
            <Typography>환불 금액</Typography>
            {refundInfo.additionalAmount > 0 ? (
              <Typography>{`${refundInfo.amount.toLocaleString()}원 + ${refundInfo.additionalAmount.toLocaleString()}원`}</Typography>
            ) : (
              <Typography>{`${refundInfo.amount.toLocaleString()}원`}</Typography>
            )}
          </Paper>

          {refundInfo.amount > 0 && (
            <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
              <Typography>환불 방식 (기존 결제방법)</Typography>
              <FormControl sx={{ pl: 1 }}>
                <RadioGroup value={book.paymentInfo.paymentType}>
                  {book.paymentInfo.paymentType === "kakaopay" && (
                    <FormControlLabel
                      value="kakaopay"
                      disabled
                      control={<Radio size="small" />}
                      label={
                        <Box display={"flex"} height={"24px"} gap={1}>
                          <img src="/images/kakaopay_icon.png" alt="kakaopay" />
                          카카오페이
                        </Box>
                      }
                    />
                  )}

                  {book.paymentInfo.paymentType === "card" && (
                    <>
                      <FormControlLabel
                        value="card"
                        disabled
                        control={<Radio size="small" />}
                        label="카드 결제"
                      />
                      <Paper elevation={2} sx={{ py: 1, px: 1, mt: 1 }}>
                        <Typography>
                          카드승인 취소 후 환불되며 취소 완료 후 영업일 기준
                          3일~5일 소요됩니다.
                        </Typography>
                        <Typography>
                          카드사의 사정에 따라 승인 취소 후 환불이 지연될 수
                          있습니다.
                        </Typography>
                      </Paper>
                    </>
                  )}

                  {book.paymentInfo.paymentType === "account" && (
                    <>
                      <FormControlLabel
                        value="account"
                        disabled
                        control={<Radio size="small" />}
                        label="가상 계좌"
                      />

                      <Box
                        display={"flex"}
                        gap={2}
                        flexDirection={isPC ? "row" : "column"}
                        alignItems={isPC ? "center" : "stretch"}
                      >
                        <FormControl>
                          <Select
                            value={bank}
                            onChange={(event) => {
                              setBank(event.target.value);
                            }}
                            sx={{
                              "& .MuiInputBase-input": { py: 1.5, px: 2 },
                            }}
                          >
                            {bankCodesLabelValue.map((bankCode) => (
                              <MenuItem
                                key={bankCode.value}
                                value={bankCode.value}
                              >
                                {bankCode.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <TextField
                          variant="standard"
                          value={bankHolder}
                          label={"예금주"}
                          onChange={(event) => {
                            setBankHolder(event.target.value);
                          }}
                          InputLabelProps={{
                            sx: { color: "commonColor.grey" },
                          }}
                          sx={{ flex: 1 }}
                        />
                        <TextField
                          variant="standard"
                          value={bankAccount}
                          label={"환불 계좌번호"}
                          onChange={(event) => {
                            setBankAccount(event.target.value);
                          }}
                          InputLabelProps={{
                            sx: { color: "commonColor.grey" },
                          }}
                          sx={{ flex: 1 }}
                        />
                      </Box>
                    </>
                  )}

                  {book.paymentInfo.paymentType === "accountTransfer" && (
                    <>
                      <FormControlLabel
                        value="accountTransfer"
                        disabled
                        control={<Radio size="small" />}
                        label="계좌 이체"
                      />

                      <Box
                        display={"flex"}
                        gap={2}
                        flexDirection={isPC ? "row" : "column"}
                        alignItems={isPC ? "center" : "stretch"}
                      >
                        <FormControl>
                          <Select
                            value={bank}
                            onChange={(event) => {
                              setBank(event.target.value);
                            }}
                            sx={{
                              "& .MuiInputBase-input": { py: 1.5, px: 2 },
                            }}
                          >
                            {bankCodesLabelValue.map((bankCode) => (
                              <MenuItem
                                key={bankCode.value}
                                value={bankCode.value}
                              >
                                {bankCode.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <TextField
                          variant="standard"
                          value={bankHolder}
                          label={"예금주"}
                          onChange={(event) => {
                            setBankHolder(event.target.value);
                          }}
                          InputLabelProps={{
                            sx: { color: "commonColor.grey" },
                          }}
                          sx={{ flex: 1 }}
                        />
                        <TextField
                          variant="standard"
                          value={bankAccount}
                          label={"환불 계좌번호"}
                          onChange={(event) => {
                            setBankAccount(event.target.value);
                          }}
                          InputLabelProps={{
                            sx: { color: "commonColor.grey" },
                          }}
                          sx={{ flex: 1 }}
                        />
                      </Box>
                    </>
                  )}
                </RadioGroup>
              </FormControl>
            </Paper>
          )}

          {refundInfo.additionalAmount > 0 && (
            <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
              <Typography>
                추가 결제에 대한 환불 (계좌이체로 환불됩니다)
              </Typography>

              <Box
                display={"flex"}
                flexDirection={isPC ? "row" : "column"}
                alignItems={isPC ? "center" : "stretch"}
                gap={2}
                mt={2}
              >
                <FormControl>
                  <Select
                    value={bank}
                    onChange={(event) => {
                      setBank(event.target.value);
                    }}
                    sx={{
                      "& .MuiInputBase-input": { py: 1.5, px: 2 },
                    }}
                  >
                    {bankCodesLabelValue.map((bankCode) => (
                      <MenuItem key={bankCode.value} value={bankCode.value}>
                        {bankCode.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  variant="standard"
                  value={bankHolder}
                  label={"예금주"}
                  onChange={(event) => {
                    setBankHolder(event.target.value);
                  }}
                  InputLabelProps={{ sx: { color: "commonColor.grey" } }}
                  sx={{ flex: 1 }}
                />
                <TextField
                  variant="standard"
                  value={bankAccount}
                  label={"환불 계좌번호"}
                  onChange={(event) => {
                    setBankAccount(event.target.value);
                  }}
                  InputLabelProps={{ sx: { color: "commonColor.grey" } }}
                  sx={{ flex: 1 }}
                />
              </Box>
            </Paper>
          )}

          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
            <TextField
              fullWidth
              variant="standard"
              value={reason}
              label={"취소 사유"}
              onChange={(event) => {
                setReason(event.target.value);
              }}
              InputLabelProps={{ sx: { color: "commonColor.grey" } }}
              sx={{ flex: 1 }}
            />
          </Paper>

          <CollapseMessage
            type="error"
            open={errorMessage !== undefined}
            message={errorMessage || ""}
            onClose={() => setErrorMessage(undefined)}
            sx={{ mt: 2 }}
          />

          <Button
            variant="contained"
            fullWidth
            disabled={loading}
            onClick={() => {
              setErrorMessage(undefined);
              if (reason.length <= 0) {
                setErrorMessage("취소 사유를 입력해주세요");
                return;
              }
              setOpenDialog(true);
            }}
            sx={{ mt: 2 }}
          >
            예약 취소
          </Button>

          <DialogYesNoAction
            title={"예약 취소 신청"}
            open={openDialog}
            color={"alert"}
            onClose={() => setOpenDialog(false)}
            onAgree={() => onCancel()}
          >
            <Typography>예약을 취소하시겠습니까?</Typography>
            <Typography>
              취소된 예약은 다시 발행할 수 없으니 신중하게 취소해주시기
              바랍니다.
            </Typography>
            <Typography>
              예약 변경 등 문의사항은 카카오톡@네츠모빌리티 고객센터로
              연락바랍니다.
            </Typography>
          </DialogYesNoAction>
        </>
      )}
      <Loading open={loading} />
    </>
  );
};
