import { Backdrop, CircularProgress } from "@mui/material";

type LoadingProps = {
  open: boolean;
};

/**
 * @param open - when open is true, loading screen is shown.
 */
export const Loading = ({ open }: LoadingProps) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
