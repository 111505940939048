import { Box, Button, Pagination, Paper, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useAuth } from "../../context/AuthContext";
import { collection, query, getDocs, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { timestampToDayjs } from "../../components/dispatchFunctions";
import { useBoardContext, BoardInfo } from "../../context/BoardContext";
import { firestore } from "../../firebase";

const VocListPage = () => {
  const navigate = useNavigate();
  const {
    isPC,
    state: { auth, info },
  } = useAuth();
  const {
    board: { lists, index },
    updateLists,
    updateIndex,
  } = useBoardContext();

  const vocsPerPage = 10;
  const [page, setPage] = useState(
    index === -1 ? 1 : Math.floor(index / vocsPerPage) + 1
  );
  const [list, setList] = useState<BoardInfo[]>(lists);

  useEffect(() => {
    getAllVocs();
  }, []);

  useEffect(() => {
    setList(
      lists.filter((_, index) => {
        return (
          index >= vocsPerPage * (page - 1) && index <= vocsPerPage * page - 1
        );
      })
    );
  }, [lists, page]);

  const getAllVocs = async () => {
    let allLists: BoardInfo[] = [];
    const vocs = await getVocs();
    const myVocs = await getMyVocs();

    allLists.push(...vocs);
    allLists.push(...myVocs);

    allLists.sort((a, b) => b.date.seconds - a.date.seconds);
    updateLists(allLists);
  };

  const getVocs = async () => {
    const ref = collection(firestore, "boards-voc");
    const q = query(ref, where("visible", "==", "공개"));
    const res = await getDocs(q);

    let list: BoardInfo[] = [];
    for (let result of res.docs) {
      if (result.exists()) {
        const notice: BoardInfo = result.data() as BoardInfo;
        list.push(notice);
      }
    }

    return list;
  };

  const getMyVocs = async () => {
    const ref = collection(firestore, "boards-voc");
    const q = query(ref, where("writerUid", "==", info.uid));
    const res = await getDocs(q);

    let list: BoardInfo[] = [];
    for (let result of res.docs) {
      if (result.exists()) {
        const notice: BoardInfo = result.data() as BoardInfo;
        if (notice.visible === "비공개") list.push(notice);
      }
    }

    return list;
  };

  const getIndex = (key: string) => {
    return lists.findIndex((voc) => voc.key === key);
  };

  const onWrite = () => {
    if (auth) {
      updateIndex(-1);
      navigate("write");
    } else {
      navigate("/auth");
    }
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      {isPC ? (
        <>
          {list.map((voc) => (
            <Paper
              key={voc.key}
              elevation={2}
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                py: 2,
                px: 4,
              }}
              onClick={() => {
                updateIndex(getIndex(voc.key));
                navigate("read");
              }}
            >
              <Box>
                {voc.visible === "비공개" && (
                  <Typography
                    fontWeight={600}
                    fontSize={"0.8rem"}
                    sx={{ color: "commonColor.warning" }}
                  >
                    관리자 확인 대기중입니다. 게시글은 스팸이 아닌 경우 내용
                    수정없이 공개 처리 됩니다.
                  </Typography>
                )}
                <Typography fontWeight={600} fontSize={"1.1rem"}>
                  {voc.title}
                </Typography>
              </Box>

              <Box
                display={"flex"}
                gap={2}
                sx={{ "& > p": { fontSize: "0.9rem" } }}
              >
                <Typography sx={{ wordBreak: "keep-all" }}>
                  {voc.writer}
                </Typography>
                <Typography>|</Typography>
                <Typography>
                  {timestampToDayjs(voc.date).format("YYYY.MM.DD")}
                </Typography>
              </Box>
            </Paper>
          ))}
        </>
      ) : (
        list.map((voc) => (
          <Paper
            key={voc.key}
            elevation={2}
            sx={{ py: 2, px: 4 }}
            onClick={() => {
              updateIndex(getIndex(voc.key));
              navigate("read");
            }}
          >
            {voc.visible === "비공개" && (
              <Typography
                fontWeight={600}
                fontSize={"0.8rem"}
                sx={{ color: "commonColor.warning" }}
              >
                관리자 확인 대기중입니다. 게시글은 스팸이 아닌 경우 내용
                수정없이 공개 처리 됩니다.
              </Typography>
            )}
            <Typography fontWeight={600} fontSize={"1.1rem"}>
              {voc.title}
            </Typography>

            <Box
              display={"flex"}
              gap={2}
              mt={1}
              sx={{ "& > p": { fontSize: "0.9rem" } }}
            >
              <Typography>{voc.writer}</Typography>
              <Typography>|</Typography>
              <Typography>
                {timestampToDayjs(voc.date).format("YYYY.MM.DD")}
              </Typography>
            </Box>
          </Paper>
        ))
      )}
      {lists.length === 0 && (
        <Paper
          elevation={2}
          sx={{
            py: 2,
            px: 4,
          }}
        >
          <Typography fontSize={"1.1rem"}>게시글이 없습니다</Typography>
        </Paper>
      )}
      <Box display={"flex"} justifyContent={"flex-end"} mt={1}>
        <Button variant="contained" sx={{ gap: 1 }} onClick={() => onWrite()}>
          <AddCircleOutlineIcon /> 새 글 쓰기
        </Button>
      </Box>
      <Box display={"flex"} justifyContent={"center"} mt={1}>
        <Pagination
          count={Math.ceil(lists.length / vocsPerPage)}
          color={"primary"}
          siblingCount={isPC ? 1 : 0}
          page={page}
          onChange={(_, value) => setPage(value)}
        />
      </Box>
    </Box>
  );
};

export default VocListPage;
