import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { PropsWithChildren } from "react";
import { gyeonggi } from "../model/region";

type DialogProps = PropsWithChildren & {
  title: string;
  open: boolean;
  fullScreen?: boolean;
  color?: "primary" | "alert";
  onClose: () => void;
  onAgree?: () => void;
};

export const DialogNoAction = ({
  title,
  open,
  color = "primary",
  fullScreen = false,
  onClose,
  children,
}: DialogProps) => {
  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen}>
      <DialogTitle
        sx={{
          m: 0,
          py: 2,
          pl: 3,
          pr: 12,
          fontSize: "1.0rem",
          backgroundColor:
            color === "primary" ? "commonColor.main" : "commonColor.alert",
          color: "white",
        }}
      >
        {title}
      </DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers sx={{ py: 2 }}>
        {children}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const DialogAgreeAction = ({
  title,
  open,
  fullScreen = false,
  color = "primary",
  onClose,
  onAgree = () => {},
  children,
}: DialogProps) => {
  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen}>
      <DialogTitle
        sx={{
          m: 0,
          py: 2,
          pl: 3,
          pr: 12,
          fontSize: "1.0rem",
          backgroundColor:
            color === "primary" ? "commonColor.main" : "commonColor.alert",
          color: "white",
        }}
      >
        {title}
      </DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers sx={{ py: 2 }}>
        {children}
      </DialogContent>
      <DialogActions>
        <Button sx={{ color: "commonColor.alert" }} onClick={onClose}>
          동의 안함
        </Button>
        <Button variant="contained" autoFocus onClick={() => onAgree()}>
          동의하기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const DialogYesNoAction = ({
  title,
  open,
  fullScreen = false,
  color = "primary",
  onClose,
  onAgree = () => {},
  children,
}: DialogProps) => {
  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen}>
      <DialogTitle
        sx={{
          m: 0,
          py: 2,
          pl: 3,
          pr: 12,
          fontSize: "1.0rem",
          backgroundColor:
            color === "primary" ? "commonColor.main" : "commonColor.alert",
          color: "white",
        }}
      >
        {title}
      </DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers sx={{ py: 2 }}>
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            color:
              color === "primary" ? "commonColor.main" : "commonColor.alert",
          }}
          onClick={onClose}
        >
          취소
        </Button>
        <Button
          variant="contained"
          autoFocus
          sx={{
            backgroundColor:
              color === "primary" ? "commonColor.main" : "commonColor.alert",

            ":hover": {
              backgroundColor:
                color === "primary" ? "#11808f" : "commonColor.alertHover",
            },
          }}
          onClick={() => onAgree()}
        >
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ShowRegionDialog = ({
  showRegion,
  setShowRegion,
}: {
  showRegion: boolean;
  setShowRegion: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Dialog open={showRegion} onClose={() => setShowRegion(false)}>
      <DialogTitle
        color={"primary"}
        sx={{
          m: 0,
          py: 2,
          pl: 3,
          pr: 12,
          width: "480px",
          fontSize: "1.0rem",
          backgroundColor: "commonColor.main",
          color: "white",
        }}
      >
        네츠모빌리티 서비스 가능 지역
      </DialogTitle>
      <IconButton
        onClick={() => setShowRegion(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers sx={{ py: 0 }}>
        <List dense={true}>
          <ListItem sx={{ pl: 0 }}>
            <ListItemText
              primary={`- 서울특별시`}
              primaryTypographyProps={{ fontWeight: "600" }}
            />
          </ListItem>
          {gyeonggi.map((loc, index) => (
            <ListItem key={index} sx={{ pl: 0 }}>
              <ListItemText
                primary={`- ${loc}`}
                primaryTypographyProps={{ fontWeight: "600" }}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => setShowRegion(false)}>
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
};
