import { Box, Typography } from "@mui/material";

const FamilySitePage = () => {
  return (
    <Box sx={{ "& > a > img": { maxWidth: "100%" } }}>
      <Typography
        component="a"
        href="https://www.gowithu.or.kr/"
        target="_blank"
      >
        <img src={"/images/family_gowithu.png"} alt="고위드유" />
      </Typography>
      <Typography component="a" href="https://monchef.co.kr/" target="_blank">
        <img src={"/images/family_monchef.png"} alt="몽쉐프" />
      </Typography>
    </Box>
  );
};

export default FamilySitePage;
