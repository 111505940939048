import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Divider,
  Paper,
  Typography,
} from "@mui/material";

import {
  BookState,
  VBankType,
  initBook,
  netsTypes,
  useBookContext,
} from "../../../context/BookContext";
import { useMediaQuery } from "react-responsive";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { useAuth } from "../../../context/AuthContext";
import { SyntheticEvent, useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../../firebase";
import { ExpandMore } from "@mui/icons-material";

export const ResultMulti = () => {
  const navigate = useNavigate();
  const {
    isPC,
    state: { auth },
  } = useAuth();

  const {
    book: { uid, nets, bookDrive, companion, paymentInfo, bookManager },
    multiBooks,
  } = useBookContext();

  // const uid = multiBooks[0].uid;
  // const nets = multiBooks[0].nets;
  // const bookDrive= multiBooks[0].bookDrive;
  // const companion= multiBooks[0].companion
  // const paymentInfo= multiBooks[0].paymentInfo

  const [expanded, setExpanded] = useState<string | false>(false);

  const [availableLists, setAvailableLists] = useState<BookState[]>([]);
  const [feeInfos, setFeeInfos] = useState<{
    totalFee: number;
    totalCoupon: number;
    totalPoint: number;
  }>({
    totalFee: 0,
    totalCoupon: 0,
    totalPoint: 0,
  });
  const [vbank, setVBank] = useState<VBankType>();

  useEffect(() => {
    if (multiBooks.length > 0) {
      let lists: BookState[] = [];
      let totalFee = 0;
      let totalCoupon = 0;
      let totalPoint = 0;

      for (let book of multiBooks) {
        lists.push(book);
        totalFee += book.paymentInfo.totalFee;
        totalCoupon += book.paymentInfo.discount.coupon;
        totalPoint += book.paymentInfo.discount.point;
      }

      lists.sort(
        (a, b) =>
          a.bookDrive.addrA.arrival.seconds - b.bookDrive.addrA.arrival.seconds
      );

      setAvailableLists(lists);
      setFeeInfos((_) => ({
        totalFee,
        totalCoupon,
        totalPoint,
      }));
    }
  }, [multiBooks]);

  useEffect(() => {
    if (
      paymentInfo.merchant_uid !== "" &&
      paymentInfo.paymentType === "account"
    )
      getVBank();
  }, [paymentInfo]);

  const getVBank = async () => {
    let merchant_uid = paymentInfo.merchant_uid.split("#")[0];
    const response = await getDoc(
      doc(firestore, "books-payment-vbank", merchant_uid)
    );
    if (response.exists()) {
      const data = response.data() as VBankType;
      setVBank(data);
    }
  };

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const getBasicFee = (
    totalFee: number,
    surCharge: boolean,
    bedwheel: boolean
  ) => {
    if (surCharge) {
      return (bedwheel ? totalFee - 11000 : totalFee) * (100 / 130) + 11000;
    } else {
      return totalFee;
    }
  };

  return (
    <Box p={1}>
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
        {paymentInfo.isPaid === "ok" ? (
          <Typography color={"primary"}>예약이 완료되었습니다</Typography>
        ) : (
          <>
            <Typography color={"primary"}>
              가상계좌 입금 대기중입니다
            </Typography>
            <Typography color={"primary"}>
              아래 계좌로 입금기한 내에 입금해 주세요.
            </Typography>
            <Typography color={"primary"}>
              입금 후 마이페이지 - 예약 내역에서 예약 정보를 확인해주세요
            </Typography>
          </>
        )}
      </Paper>

      {paymentInfo.isPaid === "waiting" &&
        paymentInfo.paymentType === "account" &&
        vbank && (
          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <Typography width={"150px"}>은행</Typography>
              <Typography color={"primary"}>{vbank.vbank_name}</Typography>
            </Box>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <Typography width={"150px"}>예금주</Typography>
              <Typography color={"primary"}>(주)네츠모빌리티</Typography>
            </Box>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <Typography width={"150px"}>계좌번호</Typography>
              <Typography color={"primary"}>{vbank.vbank_num}</Typography>
            </Box>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <Typography width={"150px"}>입금기한</Typography>
              <Typography color={"primary"}>
                {`${dayjs.unix(vbank.vbank_date).format("ll LT")} 까지`}
              </Typography>
            </Box>
          </Paper>
        )}

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        {paymentInfo.isPaid === "ok" && (
          <Box display={"flex"} gap={1} alignItems={"center"}>
            <Typography width={"150px"}>예약번호</Typography>
            <Typography color={"primary"}>
              {uid.date + String(uid.seq).padStart(3, "0")}
            </Typography>
          </Box>
        )}

        <Box display={"flex"} gap={1} alignItems={"center"} mt={1}>
          <Typography width={"150px"}>배차일자</Typography>
          <Box>
            {availableLists.map((book) => (
              <Typography key={book.uid.id} color={"primary"}>
                {`${dayjs(book.bookDrive.addrA.arrival.toDate()).format(
                  "ll (ddd)"
                )}${book.nets.surCharge ? " (할증 적용됨)" : ""}`}
              </Typography>
            ))}
          </Box>
        </Box>

        <Typography width={"150px"}>차량 도착예상시간</Typography>
        <Typography
          color={"primary"}
          ml={1}
        >{`예약 > 예약내역 확인하기에서 각 예약별 상세 내역을 확인해주세요`}</Typography>

        <Box
          sx={{
            mt: 1,
            py: 2,
            px: 2,
            borderRadius: 2,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "commonColor.main",
          }}
        >
          <Box display={"flex"} gap={2}>
            <Typography>픽업지</Typography>
          </Box>
          <Typography sx={{ mt: 0.5 }}>
            {bookDrive.addrA.addr}, {bookDrive.addrA.addrDetail}
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 1,
            py: 2,
            px: 2,
            borderRadius: 2,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "commonColor.main",
          }}
        >
          <Box display={"flex"} gap={2}>
            <Typography>목적지</Typography>
            <Typography color={"primary"}>
              {dayjs(bookDrive.addrB.arrival.toDate()).format("LT")}
            </Typography>
          </Box>
          <Typography sx={{ mt: 0.5 }}>
            {bookDrive.addrB.addr}, {bookDrive.addrB.addrDetail}
          </Typography>
        </Box>

        {bookDrive.type === "round-trip" && (
          <Box
            sx={{
              mt: 1,
              py: 2,
              px: 2,
              borderRadius: 2,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "commonColor.main",
            }}
          >
            <Box display={"flex"} gap={2}>
              <Typography>귀가지</Typography>
            </Box>
            <Typography sx={{ mt: 0.5 }}>
              {bookDrive.addrC.addr}, {bookDrive.addrC.addrDetail}
            </Typography>
          </Box>
        )}
      </Paper>

      <Paper elevation={3} sx={{ mt: 1 }}>
        <Accordion
          expanded={expanded === "price"}
          onChange={handleChange("price")}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box
              display={"flex"}
              flex={1}
              justifyContent={"space-between"}
              sx={{ mr: 1, "& > p": { fontWeight: 600 } }}
            >
              <Typography>총 서비스 금액</Typography>
              <Typography>{`${feeInfos.totalFee.toLocaleString()}원`}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {feeInfos.totalCoupon > 0 && (
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography>쿠폰 할인</Typography>
                <Typography>
                  -{feeInfos.totalCoupon.toLocaleString()}원
                </Typography>
              </Box>
            )}
            {feeInfos.totalPoint > 0 && (
              <Box display={"flex"} justifyContent={"space-between"} mt={1}>
                <Typography>포인트 사용</Typography>
                <Typography>
                  -{feeInfos.totalPoint.toLocaleString()}원
                </Typography>
              </Box>
            )}

            {availableLists.map((book, index) => (
              <Paper
                key={book.uid.id + index}
                elevation={3}
                sx={{ py: 2, px: 2, mt: 1 }}
              >
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography key={book.uid.id} color={"primary"}>
                    {`${dayjs(book.bookDrive.addrA.arrival.toDate()).format(
                      "ll (ddd)"
                    )}${book.nets.surCharge ? " (할증 적용됨)" : ""}`}
                  </Typography>
                  <Typography>{`${book.paymentInfo.totalFee.toLocaleString()}원`}</Typography>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"} mt={1}>
                  <Typography>기본 서비스 금액</Typography>
                  <Typography>{`${getBasicFee(
                    book.paymentInfo.totalFee,
                    book.nets.surCharge,
                    book.nets.bedwheel
                  ).toLocaleString()}원`}</Typography>
                </Box>
                <Paper elevation={3} sx={{ py: 1, px: 1, mt: 1 }}>
                  <>
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        fontSize={!isPC ? "0.8rem" : "0.9rem"}
                        sx={{ ml: 2 }}
                      >
                        - {netsTypes[book.nets.type].label}
                      </Typography>
                      <Typography
                        fontSize={!isPC ? "0.8rem" : "0.9rem"}
                        sx={{ ml: 2 }}
                      >
                        {book.nets.type === "move"
                          ? netsTypes[book.nets.type].fee.toLocaleString()
                          : netsTypes[book.nets.type][
                              book.bookDrive.type
                            ].toLocaleString()}
                        원
                      </Typography>
                    </Box>

                    {book.nets.type === "wheel-plus" && (
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography
                          fontSize={!isPC ? "0.8rem" : "0.9rem"}
                          sx={{ ml: 2 }}
                        >
                          {`- 계단 이동 : ${book.nets.floor.label} (+${
                            book.nets.floor.value
                          }) ${
                            book.bookDrive.type === "round-trip"
                              ? "(왕복시 x2)"
                              : ""
                          }`}
                        </Typography>
                        <Typography fontSize={!isPC ? "0.8rem" : "0.9rem"}>
                          {(book.bookDrive.type === "round-trip"
                            ? book.nets.floor.value * 2
                            : book.nets.floor.value
                          ).toLocaleString()}
                          원
                        </Typography>
                      </Box>
                    )}
                    {book.nets.bedwheel && (
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography
                          fontSize={!isPC ? "0.8rem" : "0.9rem"}
                          sx={{ ml: 2 }}
                        >
                          - 침대형 거상 이용
                        </Typography>
                        <Typography fontSize={!isPC ? "0.8rem" : "0.9rem"}>
                          11,000원
                        </Typography>
                      </Box>
                    )}

                    {book.bookDrive.type === "round-trip" && (
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        ml={2}
                      >
                        <Box>
                          <Typography fontSize={!isPC ? "0.8rem" : "0.9rem"}>
                            - 목적지에 머무는 시간{" "}
                            {!!isPC && (
                              <Typography component="span" fontSize={"0.8rem"}>
                                (1시간 무료, 추가 20분당 8,000원)
                              </Typography>
                            )}
                          </Typography>
                          {!isPC && (
                            <Typography
                              fontSize={!isPC ? "0.7rem" : "0.8rem"}
                              sx={{ ml: 1 }}
                            >
                              (1시간 무료, 추가 20분당 8,000원)
                            </Typography>
                          )}
                          <Typography
                            fontSize={!isPC ? "0.8rem" : "0.9rem"}
                            sx={{ ml: 2 }}
                          >
                            {`- ${book.companion.stayHour}시간 ${book.companion.stayMin}분 `}
                          </Typography>
                        </Box>
                        <Typography>
                          {book.companion.companionFee.toLocaleString()}원
                        </Typography>
                      </Box>
                    )}

                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      ml={2}
                    >
                      <Box>
                        <Typography fontSize={!isPC ? "0.8rem" : "0.9rem"}>
                          {`- 총 이동거리: 
              ${(
                (book.bookDrive.addrA.distanceToNext +
                  book.bookDrive.addrB.distanceToNext) /
                1000
              ).toFixed(2)}km `}
                          {!!isPC && (
                            <Typography component="span" fontSize={"0.8rem"}>
                              (편도당 10km 무료, 추가 5km당 11,000원)
                            </Typography>
                          )}
                        </Typography>

                        {!isPC && (
                          <Typography
                            fontSize={!isPC ? "0.7rem" : "0.8rem"}
                            sx={{ ml: 1 }}
                          >
                            (편도당 10km 무료, 추가 5km당 11,000원)
                          </Typography>
                        )}
                      </Box>
                      <Typography minWidth={"80px"} align="right">
                        {(
                          book.bookDrive.addrA.feeToNext +
                          book.bookDrive.addrB.feeToNext
                        ).toLocaleString()}
                        원
                      </Typography>
                    </Box>
                    {book.bookDrive.addrA.feeToNext +
                      book.bookDrive.addrB.feeToNext >
                      0 && (
                      <>
                        {book.bookDrive.addrA.feeToNext > 0 && (
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            <Typography
                              fontSize={!isPC ? "0.8rem" : "0.9rem"}
                              sx={{ ml: 2 }}
                            >
                              {`- 픽업지 ➝ 목적지: ${(
                                book.bookDrive.addrA.distanceToNext / 1000
                              ).toFixed(2)}km`}
                            </Typography>

                            <Typography
                              fontSize={!isPC ? "0.8rem" : "0.9rem"}
                              sx={{ ml: 2 }}
                            >
                              {`${book.bookDrive.addrA.feeToNext.toLocaleString()}원`}
                            </Typography>
                          </Box>
                        )}
                        {book.bookDrive.addrB.feeToNext > 0 && (
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            <Typography
                              fontSize={!isPC ? "0.8rem" : "0.9rem"}
                              sx={{ ml: 2 }}
                            >
                              {`- 목적지 ➝ 귀가지: ${(
                                book.bookDrive.addrB.distanceToNext / 1000
                              ).toFixed(2)}km`}
                            </Typography>

                            <Typography
                              fontSize={!isPC ? "0.8rem" : "0.9rem"}
                              sx={{ ml: 2 }}
                            >
                              {`${book.bookDrive.addrB.feeToNext.toLocaleString()}원`}
                            </Typography>
                          </Box>
                        )}

                        {book.bookDrive.sameReturn && (
                          <Typography
                            fontSize={!isPC ? "0.8rem" : "0.9rem"}
                            sx={{ ml: 2 }}
                          >
                            * 왕복시 픽업지와 귀가지가 동일한 경우 짧은
                            이동거리에 맞춰 계산합니다
                          </Typography>
                        )}
                      </>
                    )}
                  </>
                </Paper>
                {book.nets.surCharge && (
                  <Box display={"flex"} justifyContent={"space-between"} mt={2}>
                    <Typography>할증 요금 (30%)</Typography>
                    <Typography>{`${(
                      book.paymentInfo.totalFee -
                      getBasicFee(
                        book.paymentInfo.totalFee,
                        true,
                        book.nets.bedwheel
                      )
                    ).toLocaleString()}원`}</Typography>
                  </Box>
                )}
              </Paper>
            ))}
          </AccordionDetails>
        </Accordion>
      </Paper>

      <Button
        variant="contained"
        fullWidth
        sx={{ mt: 2, fontWeight: "600", fontSize: "1.1rem" }}
        onClick={() => navigate("/")}
      >
        홈으로
      </Button>
      <script
        type="text/javascript"
        src="https://wcs.naver.net/wcslog.js"
      ></script>
      <script type="text/javascript">
        var _nasa={}; if(window.wcs) _nasa["cnv"] = wcs.cnv("1","100");
      </script>
    </Box>
  );
};
