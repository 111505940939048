import {
  runTransaction,
  doc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import { BookState } from "../context/BookContext";
import { firestore } from "../firebase";

export const onRenewBook = (uidList: string[]) => {
  return new Promise<void>((resolve, reject) => {
    let request: Promise<BookState>[] = uidList.map((uid) => getBook(uid));

    Promise.all(request)
      .then((books) => {
        runTransaction(firestore, async (transaction) => {
          for (let book of books) {
            // update creationDate
            transaction.update(doc(firestore, "books", book.uid.id), {
              ...book,
              creationDate: serverTimestamp(),
            });
          }
        })
          .then(() => {
            console.log("creationDate 갱신");
            resolve();
          })
          .catch((e) => {
            throw e;
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getBook = (uid: string) => {
  return new Promise<BookState>((resolve, reject) => {
    getDoc(doc(firestore, "books", uid))
      .then((value) => {
        if (value.exists()) {
          resolve(value.data() as BookState);
        } else {
          reject("no data");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
