import { Button } from "@mui/material";
import dayjs from "dayjs";
import { useDaumPostcodePopup } from "react-daum-postcode";
import {
  Address,
  postcodeScriptUrl,
} from "react-daum-postcode/lib/loadPostcode";
import { GeolocationType } from "../context/BookContext";
import { Timestamp } from "firebase/firestore";

declare global {
  interface Window {
    kakao: any;
  }
}

const { kakao } = window;

type PostProps = {
  result: (address: GeolocationType) => void;
};

export const PostCodeButton = ({ result }: PostProps) => {
  const open = useDaumPostcodePopup(postcodeScriptUrl);

  let geocoder = new kakao.maps.services.Geocoder();
  const handleComplete = (data: Address) => {
    geocoder.addressSearch(data.address, (res: any, status: any) => {
      if (status === window.kakao.maps.services.Status.OK) {
        result({
          addr: data.address,
          addrDetail: data.buildingName,
          nickname: "",
          arrival: Timestamp.fromDate(dayjs().add(1, "day").toDate()),
          latitude: Number(res[0].y),
          longitude: Number(res[0].x),
          distanceToNext: 0,
          durationToNext: 0,
          feeToNext: 0,
        });
      }
    });

    // let fullAddress = data.address;
    // let extraAddress = ""; //추가될 주소
    // let localAddress = data.sido + " " + data.sigungu; //지역주소(시, 도 + 시, 군, 구)
    // if (data.addressType === "R") {
    //   //주소타입이 도로명주소일 경우
    //   // if (data.bname !== "") {
    //   //   extraAddress += data.bname; //법정동, 법정리
    //   // }
    //   // if (data.buildingName !== "") {
    //   //   //건물명
    //   //   extraAddress +=
    //   //     extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
    //   // }

    //   //지역주소 제외 전체주소 치환
    //   // fullAddress = fullAddress.replace(localAddress, "");

    //   //조건 판단 완료 후 지역 주소 및 상세주소 state 수정
    //   // props.setAddressObj({
    //   //   areaAddress: localAddress,
    //   //   townAddress: fullAddress += (extraAddress !== '' ? `(${extraAddress})` : '')
    //   // });
    //   //주소 검색이 완료된 후 결과를 매개변수로 전달
    //   // 다음에 수행할 작업을 명시
    // }
  };
  //클릭 시 발생할 이벤트
  const handleClick = () => {
    //주소검색이 완료되고, 결과 주소를 클릭 시 해당 함수 수행
    open({ onComplete: handleComplete });
  };
  return (
    <Button variant="contained" onClick={handleClick}>
      주소 검색
    </Button>
  );
};
