import serviceRegions from "./serviceRegions.json";

export const seoul = ["서울", "서울특별시"];
// export const gyeonggi = [
//   "고양시",
//   "과천시",
//   "광명시",
//   "광주시",
//   "구리시",
//   "군포시",
//   "남양주시",
//   "부천시",
//   "성남시",
//   "수원시",
//   "안양시",
//   "양주시",
//   "용인시",
//   "의정부시",
//   "파주시",
//   "하남시",
// ];
const regions = Object.values(serviceRegions).sort();
export const gyeonggi = regions.filter((value) => value !== "서울");
