import { Avatar, Box, Button, Divider, Paper, Typography } from "@mui/material";
import {
  VBankType,
  netsTypes,
  useBookContext,
} from "../../../context/BookContext";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useAuth } from "../../../context/AuthContext";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../../firebase";

export const Result = () => {
  const navigate = useNavigate();
  const {
    isPC,
    state: { auth },
  } = useAuth();

  const {
    book: { uid, nets, bookDrive, companion, paymentInfo, bookManager },
  } = useBookContext();

  const [vbank, setVBank] = useState<VBankType>();

  useEffect(() => {
    if (
      paymentInfo.merchant_uid !== "" &&
      paymentInfo.paymentType === "account"
    )
      getVBank();
  }, [paymentInfo]);

  const getVBank = async () => {
    let merchant_uid = paymentInfo.merchant_uid.split("#")[0];
    const response = await getDoc(
      doc(firestore, "books-payment-vbank", merchant_uid)
    );
    if (response.exists()) {
      const data = response.data() as VBankType;
      setVBank(data);
    }
  };

  return (
    <Box p={1}>
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
        {paymentInfo.isPaid === "ok" ? (
          <Typography color={"primary"}>예약이 완료되었습니다</Typography>
        ) : (
          <>
            <Typography color={"primary"}>
              가상계좌 입금 대기중입니다
            </Typography>
            <Typography color={"primary"}>
              아래 계좌로 입금기한 내에 입금해 주세요.
            </Typography>
            <Typography color={"primary"}>
              입금 후 마이페이지 - 예약 내역에서 예약 정보를 확인해주세요
            </Typography>
          </>
        )}
      </Paper>

      {paymentInfo.isPaid === "waiting" &&
        paymentInfo.paymentType === "account" &&
        vbank && (
          <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <Typography width={"150px"}>은행</Typography>
              <Typography color={"primary"}>{vbank.vbank_name}</Typography>
            </Box>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <Typography width={"150px"}>예금주</Typography>
              <Typography color={"primary"}>(주)네츠모빌리티</Typography>
            </Box>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <Typography width={"150px"}>계좌번호</Typography>
              <Typography color={"primary"}>{vbank.vbank_num}</Typography>
            </Box>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <Typography width={"150px"}>입금기한</Typography>
              <Typography color={"primary"}>
                {`${dayjs.unix(vbank.vbank_date).format("ll LT")} 까지`}
              </Typography>
            </Box>
          </Paper>
        )}

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        {paymentInfo.isPaid === "ok" && (
          <Box display={"flex"} gap={1} alignItems={"center"}>
            <Typography width={"150px"}>예약번호</Typography>
            <Typography color={"primary"}>
              {uid.date + String(uid.seq).padStart(3, "0")}
            </Typography>
          </Box>
        )}

        <Box display={"flex"} gap={1} alignItems={"center"} mt={1}>
          <Typography width={"150px"}>배차일자</Typography>
          <Typography color={"primary"}>
            {dayjs(bookDrive.addrA.arrival.toDate()).format("ll (ddd)")}
          </Typography>
        </Box>

        <Typography sx={{ mt: 1 }}>서비스 시간</Typography>
        <Box
          sx={{
            mt: 1,
            py: 2,
            px: 2,
            borderRadius: 2,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "commonColor.main",
          }}
        >
          <Box display={"flex"} gap={2}>
            <Typography>픽업지 차량 도착</Typography>
            <Typography color={"primary"}>
              {dayjs(bookDrive.addrA.arrival.toDate()).format("LT")}
            </Typography>
          </Box>
          <Typography sx={{ mt: 0.5 }}>
            {bookDrive.addrA.addr}, {bookDrive.addrA.addrDetail}
          </Typography>
        </Box>

        <Box
          sx={{
            mt: 1,
            py: 2,
            px: 2,
            borderRadius: 2,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "commonColor.main",
          }}
        >
          <Box display={"flex"} gap={2}>
            <Typography>목적지 차량 도착</Typography>
            <Typography color={"primary"}>
              {dayjs(bookDrive.addrB.arrival.toDate()).format("LT")}
            </Typography>
          </Box>
          <Typography sx={{ mt: 0.5 }}>
            {bookDrive.addrB.addr}, {bookDrive.addrB.addrDetail}
          </Typography>
        </Box>

        {bookDrive.type === "round-trip" && (
          <Box
            sx={{
              mt: 1,
              py: 2,
              px: 2,
              borderRadius: 2,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "commonColor.main",
            }}
          >
            <Box display={"flex"} gap={2}>
              <Typography>목적지 차량 출발</Typography>
              <Typography color={"primary"}>
                {dayjs(bookDrive.addrB.arrival.toDate())
                  .add(companion.stayHour, "hour")
                  .add(companion.stayMin, "minute")
                  .format("LT")}
              </Typography>
            </Box>
            <Typography sx={{ mt: 0.5 }}>
              {`귀가지 : ${bookDrive.addrC.addr}, ${bookDrive.addrC.addrDetail}`}
            </Typography>
          </Box>
        )}
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography>총 결제 금액</Typography>
          <Typography>{`${paymentInfo.fee.toLocaleString()}원`}</Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography>네츠 서비스</Typography>
          <Typography>{nets.netsFee.toLocaleString()}원</Typography>
        </Box>

        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography fontSize={isPC ? "0.9rem" : "0.8rem"} sx={{ ml: 2 }}>
            - {netsTypes[nets.type].label}
          </Typography>
          <Typography fontSize={isPC ? "0.9rem" : "0.8rem"} sx={{ ml: 2 }}>
            {`${(nets.type === "move"
              ? netsTypes[nets.type].fee
              : netsTypes[nets.type][bookDrive.type]
            ).toLocaleString()}원`}
          </Typography>
        </Box>
        {nets.type === "wheel-plus" && (
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box>
              <Typography fontSize={isPC ? "0.9rem" : "0.8rem"} sx={{ ml: 2 }}>
                {`- 계단 이동 : ${nets.floor.label} (+ ${nets.floor.value}) ${
                  isPC ? "(왕복시 x2)" : ""
                } `}
              </Typography>
              {!isPC && bookDrive.type === "round-trip" && (
                <Typography fontSize={"0.8rem"} ml={3}>
                  (왕복시 x2)
                </Typography>
              )}
            </Box>
            <Typography fontSize={isPC ? "0.9rem" : "0.8rem"}>
              {(bookDrive.type === "round-trip"
                ? nets.floor.value * 2
                : nets.floor.value
              ).toLocaleString()}
              원
            </Typography>
          </Box>
        )}
        {nets.bedwheel && (
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography fontSize={isPC ? "0.9rem" : "0.8rem"} sx={{ ml: 2 }}>
              - 침대형 거상 이용 (+ 11,000원)
            </Typography>
            <Typography fontSize={isPC ? "0.9rem" : "0.8rem"}>
              11,000원
            </Typography>
          </Box>
        )}

        {bookDrive.type === "round-trip" && companion.companionFee > 0 && (
          <Box display={"flex"} justifyContent={"space-between"} mt={1}>
            <Box>
              <Typography>
                목적지 대기 추가요금{" "}
                {isPC && (
                  <Typography component="span" fontSize={"0.8rem"}>
                    (1시간 무료, 추가 20분당 8,000원)
                  </Typography>
                )}
              </Typography>
              {!isPC && (
                <Typography fontSize={"0.7rem"} sx={{ ml: 1 }}>
                  (1시간 무료, 추가 20분당 8,000원)
                </Typography>
              )}
              <Typography fontSize={isPC ? "0.9rem" : "0.8rem"} sx={{ ml: 2 }}>
                {`- ${companion.stayHour}시간 ${companion.stayMin}분 `}
              </Typography>
            </Box>
            <Typography>{companion.companionFee.toLocaleString()}원</Typography>
          </Box>
        )}

        <Box display={"flex"} justifyContent={"space-between"} mt={1}>
          <Box>
            <Typography>
              이동거리 추가요금
              {/* {`이동거리 추가요금
              ${(
                (bookDrive.addrA.distanceToNext +
                  bookDrive.addrB.distanceToNext) /
                1000
              ).toFixed(2)}km `} */}
              {isPC && (
                <Typography component="span" fontSize={"0.8rem"} ml={1}>
                  (편도당 10km 무료, 추가 5km당 11,000원)
                </Typography>
              )}
            </Typography>

            {!isPC && (
              <Typography fontSize={"0.7rem"} sx={{ ml: 1 }}>
                (편도당 10km 무료, 추가 5km당 11,000원)
              </Typography>
            )}
          </Box>
          <Typography minWidth={"80px"} align="right">
            {(
              bookDrive.addrA.feeToNext + bookDrive.addrB.feeToNext
            ).toLocaleString()}
            원
          </Typography>
        </Box>
        {bookDrive.addrA.feeToNext + bookDrive.addrB.feeToNext > 0 && (
          <>
            {bookDrive.addrA.feeToNext > 0 && (
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography
                  fontSize={isPC ? "0.9rem" : "0.8rem"}
                  sx={{ ml: 2 }}
                >
                  {`- 픽업지 ➝ 목적지: ${(
                    bookDrive.addrA.distanceToNext / 1000
                  ).toFixed(2)}km`}
                </Typography>

                <Typography
                  fontSize={isPC ? "0.9rem" : "0.8rem"}
                  sx={{ ml: 2 }}
                >
                  {`${bookDrive.addrA.feeToNext.toLocaleString()}원`}
                </Typography>
              </Box>
            )}
            {bookDrive.addrB.feeToNext > 0 && (
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography
                  fontSize={isPC ? "0.9rem" : "0.8rem"}
                  sx={{ ml: 2 }}
                >
                  {`- 목적지 ➝ 귀가지: ${(
                    bookDrive.addrB.distanceToNext / 1000
                  ).toFixed(2)}km`}
                </Typography>

                <Typography
                  fontSize={isPC ? "0.9rem" : "0.8rem"}
                  sx={{ ml: 2 }}
                >
                  {`${bookDrive.addrB.feeToNext.toLocaleString()}원`}
                </Typography>
              </Box>
            )}

            {bookDrive.type === "round-trip" && bookDrive.sameReturn && (
              <Typography
                fontSize={isPC ? "0.9rem" : "0.8rem"}
                sx={{
                  ml: 2,
                  wordBreak: "keep-all",
                  color: "commonColor.warning",
                }}
              >
                * 왕복시 픽업지와 귀가지가 동일한 경우 짧은 이동거리에 맞춰
                계산합니다
              </Typography>
            )}
          </>
        )}

        {nets.surCharge && (
          <>
            <Box display={"flex"} justifyContent={"space-between"} mt={1}>
              <Typography>할증 요금 (30%)</Typography>
              <Typography>
                {(
                  ((paymentInfo.totalFee - (nets.bedwheel ? 11000 : 0)) / 1.3) *
                  0.3
                ).toLocaleString()}
                원
              </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography fontSize={isPC ? "0.9rem" : "0.8rem"} sx={{ ml: 2 }}>
                - {netsTypes[nets.type].label}
              </Typography>
              <Typography fontSize={isPC ? "0.9rem" : "0.8rem"} sx={{ ml: 2 }}>
                {`${(
                  (nets.type === "move"
                    ? netsTypes[nets.type].fee
                    : netsTypes[nets.type][bookDrive.type]) * 0.3
                ).toLocaleString()}원`}
              </Typography>
            </Box>
            {nets.type === "wheel-plus" && (
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box>
                  <Typography
                    fontSize={isPC ? "0.9rem" : "0.8rem"}
                    sx={{ ml: 2 }}
                  >
                    {`- 계단 이동 : ${nets.floor.label} (+ ${
                      nets.floor.value
                    }) ${isPC ? "(왕복시 x2)" : ""} `}
                  </Typography>
                  {!isPC && bookDrive.type === "round-trip" && (
                    <Typography fontSize={"0.8rem"} ml={3}>
                      (왕복시 x2)
                    </Typography>
                  )}
                </Box>
                <Typography fontSize={isPC ? "0.9rem" : "0.8rem"}>
                  {(
                    (bookDrive.type === "round-trip"
                      ? nets.floor.value * 2
                      : nets.floor.value) * 0.3
                  ).toLocaleString()}
                  원
                </Typography>
              </Box>
            )}
            {bookDrive.type === "round-trip" && (
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography
                  fontSize={isPC ? "0.9rem" : "0.8rem"}
                  sx={{ ml: 2 }}
                >
                  {`- 목적지에 머무는 시간 : ${companion.stayHour}시간 ${companion.stayMin}분`}
                </Typography>
                <Typography fontSize={isPC ? "0.9rem" : "0.8rem"}>
                  {(companion.companionFee * 0.3).toLocaleString()}원
                </Typography>
              </Box>
            )}

            {bookDrive.type === "round-trip" ? (
              bookDrive.addrA.feeToNext + bookDrive.addrB.feeToNext > 0 ? (
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Box>
                    <Typography
                      fontSize={isPC ? "0.9rem" : "0.8rem"}
                      sx={{ ml: 2 }}
                    >
                      {isPC
                        ? `- 이동거리 추가요금 : ${(
                            bookDrive.addrA.distanceToNext / 1000
                          ).toFixed(2)}km + ${(
                            bookDrive.addrB.distanceToNext / 1000
                          ).toFixed(2)}km`
                        : "- 이동거리 추가요금 : "}
                    </Typography>
                    {!isPC && (
                      <Typography fontSize={"0.8rem"} ml={3}>{`${(
                        bookDrive.addrA.distanceToNext / 1000
                      ).toFixed(2)}km + ${(
                        bookDrive.addrB.distanceToNext / 1000
                      ).toFixed(2)}km`}</Typography>
                    )}
                  </Box>
                  <Typography fontSize={isPC ? "0.9rem" : "0.8rem"}>
                    {(
                      (bookDrive.addrA.feeToNext + bookDrive.addrB.feeToNext) *
                      0.3
                    ).toLocaleString()}
                    원
                  </Typography>
                </Box>
              ) : (
                <></>
              )
            ) : bookDrive.addrA.feeToNext > 0 ? (
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography
                  fontSize={isPC ? "0.9rem" : "0.8rem"}
                  sx={{ ml: 2 }}
                >
                  {`- 이동거리 추가요금 : ${(
                    bookDrive.addrA.distanceToNext / 1000
                  ).toFixed(2)}km`}
                </Typography>
                <Typography fontSize={isPC ? "0.9rem" : "0.8rem"}>
                  {(bookDrive.addrA.feeToNext * 0.3).toLocaleString()}원
                </Typography>
              </Box>
            ) : (
              <></>
            )}
          </>
        )}

        {paymentInfo.discount.couponCode !== "" && (
          <Box display={"flex"} justifyContent={"space-between"} mt={1}>
            <Typography>쿠폰 할인</Typography>
            <Typography>
              -{paymentInfo.discount.coupon.toLocaleString()}원
            </Typography>
          </Box>
        )}
        {paymentInfo.discount.point !== 0 && (
          <Box display={"flex"} justifyContent={"space-between"} mt={1}>
            <Typography>포인트 사용</Typography>
            <Typography>
              -{paymentInfo.discount.point.toLocaleString()}원
            </Typography>
          </Box>
        )}
      </Paper>

      {paymentInfo.isPaid === "ok" && (
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
          <Typography>배차 정보</Typography>
          <Typography color={"primary"} sx={{ mt: 1 }}>
            차량번호 {bookManager.car}
          </Typography>

          <Paper
            elevation={1}
            sx={{
              mt: 1,
              p: 2,
              borderRadius: 4,
            }}
          >
            <Box display={"flex"} gap={3}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
              >
                <Avatar sx={{ width: 64, height: 64 }} src={bookManager.photo}>
                  사진
                </Avatar>
                <Typography sx={{ textWrap: "nowrap" }}>
                  {`${bookManager.name} 매니저`}
                </Typography>
              </Box>

              <Typography
                dangerouslySetInnerHTML={{ __html: bookManager.description }}
                sx={{ "& > figure > img": { width: "100%" } }}
              ></Typography>
            </Box>
          </Paper>
        </Paper>
      )}
      <Button
        variant="contained"
        fullWidth
        sx={{ mt: 2, fontWeight: "600", fontSize: "1.1rem" }}
        onClick={() => navigate("/")}
      >
        홈으로
      </Button>
      <script
        type="text/javascript"
        src="https://wcs.naver.net/wcslog.js"
      ></script>
      <script type="text/javascript">
        var _nasa={}; if(window.wcs) _nasa["cnv"] = wcs.cnv("1","100");
      </script>
    </Box>
  );
};
