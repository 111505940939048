import { Box } from "@mui/material";

const IntroducePage = () => {
  return (
    <Box sx={{ "& > img": { maxWidth: "100%" }, mt: 4 }}>
      <img src={"/images/intro_1.jpg"} alt="네츠모빌리티 모빌리티" />
      <img src={"/images/intro_2.jpg"} alt="네츠모빌리티 서비스" />
      <img src={"/images/intro_3.jpg"} alt="네츠모빌리티 이용안내" />
      <img src={"/images/intro_4.jpg"} alt="네츠모빌리티 파트너사" />
    </Box>
  );
};

export default IntroducePage;
