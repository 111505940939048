import {
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  PatientType,
  initBook,
  useBookContext,
} from "../../context/BookContext";
import { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { useAuth } from "../../context/AuthContext";
export const Menu = () => {
  const state = useLocation().state;
  const {
    state: { info },
  } = useAuth();
  const navigate = useNavigate();
  const {
    book: { patient },
    updateBook,
    updateMultiBookUids,
  } = useBookContext();
  const [type, setType] = useState<PatientType | "">("");

  useEffect(() => {
    if (info.uid === "") navigate("/", { replace: true });
  }, [info]);

  const onNext = () => {
    if (type === "") return;

    const book = cloneDeep(initBook);
    book.patient.type = type;
    book.uid.bookUser = info.uid;
    book.guardian.name = info.customer!.name;
    book.guardian.phone = info.customer!.phone;
    // book.paymentInfo.cashReceiptsNumber = info.customer!.cashReceipts
    updateBook(book);

    navigate("nets", { state: { init: "init" } });
  };

  const onLoadPrev = () => {
    navigate("prevBooks");
  };

  const navToMulti = () => {
    if (type === "") return;

    const multiBook = cloneDeep(initBook);
    multiBook.patient.type = type;
    multiBook.uid.bookUser = info.uid;
    multiBook.guardian.name = info.customer!.name;
    multiBook.guardian.phone = info.customer!.phone;
    // multiBook.paymentInfo.cashReceiptsNumber = info.customer!.cashReceipts
    multiBook.type = "multi";

    updateBook(multiBook);
    updateMultiBookUids([], []);
    navigate("nets", { state: { init: "init" } });
  };

  return (
    <>
      {state && state.callByTimer && (
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
          <Typography color={"commonColor.alert"}>
            장시간 입력이 없어 예약이 취소되었습니다.
          </Typography>
          <Typography color={"commonColor.alert"}>
            다시 예약을 진행해주세요.
          </Typography>
        </Paper>
      )}
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
        <Typography color={"primary"}>
          네츠모빌리티는 과기부 특례로 병원이동(내원, 입원 및 퇴원,
          요양(재활)병원 외출) 시에만 이용 가능한 예약제 서비스입니다
        </Typography>
      </Paper>
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Typography>이용대상 해당 여부 확인</Typography>
        <FormControl sx={{ pl: 1, pt: 1 }}>
          <RadioGroup
            value={type}
            onChange={(_, value) => setType(value as unknown as PatientType)}
          >
            <FormControlLabel
              value="over65"
              control={<Radio size="small" />}
              label="65세 이상 고령자"
            />
            <FormControlLabel
              value="disabled"
              control={<Radio size="small" />}
              label="장애인"
            />
            <FormControlLabel
              value="limited"
              control={<Radio size="small" />}
              label="거동 불편"
            />
          </RadioGroup>
        </FormControl>
      </Paper>

      <Button
        variant="contained"
        fullWidth
        disabled={type === ""}
        onClick={() => onNext()}
        sx={{ mt: 2 }}
      >
        신규 예약
      </Button>
      <Button
        variant="contained"
        fullWidth
        onClick={() => onLoadPrev()}
        sx={{ mt: 1 }}
      >
        기존 예약정보 불러오기
      </Button>
      <Button
        variant="contained"
        fullWidth
        disabled={type === ""}
        onClick={() => navToMulti()}
        sx={{ mt: 1 }}
      >
        정기 예약(동일병원, 동일 시간의 다회 예약)
      </Button>
    </>
  );
};
