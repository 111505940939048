import { Box, Button, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { Input } from "../../components/muiInput";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { FirebaseError } from "firebase/app";
import { CollapseMessage } from "../../components/collapseMessage";
import { useState } from "react";

export const ForgotPassword = () => {
  const navigate = useNavigate();

  const [message, setMessage] = useState<string>();
  const { control, handleSubmit } = useForm<{ email: string }>({
    defaultValues: {
      email: "",
    },
  });

  const sendMail = ({ email }: { email: string }) => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        navigate("../forgotSendEmail", { state: { email }, replace: true });
      })
      .catch((error) => {
        if (error instanceof FirebaseError) {
          switch (error.code) {
            case "auth/user-not-found":
              setMessage("등록되지 않은 아이디입니다.");
              break;
            case "auth/invalid-email":
              setMessage("잘못된 이메일 형식입니다.");
              break;
            case "auth/network-request-failed":
              setMessage("네트워크 연결에 실패 하였습니다.");
              break;
            case "auth/internal-error":
              setMessage("잘못된 요청입니다.");
              break;
            case "auth/too-many-requests":
              setMessage(
                "비밀번호 찾기 요청을 너무 많이 하셨습니다. 잠시 후 다시 시도해주세요."
              );
              break;

            default:
              setMessage(`비밀번호 찾기에 실패했습니다: ${error.code}`);
          }
        }
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        // scrollMarginTop: 75,
      }}
    >
      <form onSubmit={handleSubmit(sendMail)}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography color={"primary"} sx={{ textAlign: "center", mb: 4 }}>
            비밀번호를 분실하셨나요?
          </Typography>
          <Typography>
            네츠모빌리티에 가입한 이메일 주소를 입력해주세요.
          </Typography>
          <Input
            name={"email"}
            control={control}
            textFieldProps={{ label: "이메일 주소", sx: { width: "20rem" } }}
            rules={{
              required: { value: true, message: "이메일 주소를 입력해주세요." },
              pattern: {
                value:
                  /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
                message: "이메일 주소를 입력해주세요.",
              },
            }}
          />

          <CollapseMessage
            open={message !== undefined}
            type={"warning"}
            message={message || ""}
            onClose={() => setMessage(undefined)}
            sx={{ mt: 1, mb: 2, width: "20rem" }}
          />

          <Button
            type="submit"
            size="large"
            variant="contained"
            fullWidth
            sx={{ width: "20rem" }}
          >
            비밀번호 재설정 신청하기
          </Button>
        </Box>
      </form>
    </Box>
  );
};
