import { Box, Chip, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BookState, useBookContext } from "../../context/BookContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../firebase";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { timestampToDayjs } from "../../components/dispatchFunctions";
import { Loading } from "../../components/loading";

const ManageBooksPage = () => {
  const {
    isPC,
    state: { info },
  } = useAuth();
  const { updateBook, updateMultiBookUids } = useBookContext();
  const navigate = useNavigate();

  const [books, setBooks] = useState<BookState[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const ref = collection(firestore, "books");
    const q = query(ref, where("uid.bookUser", "==", info.uid));

    const datas: BookState[] = [];
    getDocs(q).then((value) => {
      for (let doc of value.docs) {
        const data = doc.data() as BookState;
        datas.push(data);
      }

      datas.sort((a, b) => b.creationDate.seconds - a.creationDate.seconds);

      setBooks(datas);
    });
  }, []);

  const onNext = async (book: BookState) => {
    updateBook(book);
    if (book.paymentInfo.isTemp) {
      if (book.type === "single") navigate("/reservation/patient");
      else {
        if (book.uid.multiId) {
          setLoading(true);
          try {
            const res = await getDocs(
              query(
                collection(firestore, "books"),
                where("uid.multiId", "==", book.uid.multiId)
              )
            );
            let books: BookState[] = [];
            let uidLists: string[] = [];
            res.forEach((result) => {
              const book = result.data() as BookState;
              books.push(book);
              uidLists.push(book.uid.id);
            });
            updateMultiBookUids(uidLists, books);

            setLoading(false);
            // console.log(lists);
            navigate("/reservation/patient");
          } catch (error) {
            setLoading(false);
            console.log(error);
          }
        }
      }
    } else navigate("detail");
  };

  return (
    <>
      <Loading open={loading} />
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2, mb: 1 }}>
          <Typography color={"primary"}>예약 내역입니다.</Typography>
        </Paper>
        <Grid container columnSpacing={2} rowSpacing={1}>
          {books.map((book, index) => (
            <Grid key={book.uid.id + index} item pc={6} mobile={12} p={0}>
              <Paper
                elevation={1}
                onClick={() => onNext(book)}
                sx={{
                  py: 1,
                  px: 2,
                  border: "1px solid #19B7CD",
                  cursor: "pointer",
                  ".MuiTypography-root": {
                    fontSize: "0.9rem",
                  },
                }}
              >
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography>{`예약번호: ${book.uid.id}`}</Typography>
                  <StateChip book={book} />
                </Box>
                <Grid
                  container
                  columnSpacing={2}
                  sx={{ mt: 1, alignItems: "center" }}
                >
                  <Grid item pc={12} mobile={12}>
                    <Typography>
                      {`${timestampToDayjs(book.bookDrive.addrB.arrival).format(
                        "l LT"
                      )}`}
                    </Typography>
                    {/* <Typography>
                        {`${timestampToDayjs(
                          book.bookDrive.addrB.arrival
                        ).format("LT")}`}
                      </Typography> */}
                  </Grid>
                  <Grid item pc={12} mobile={12}>
                    <Typography>{`${
                      book.bookDrive.addrB.nickname === ""
                        ? book.bookDrive.addrB.addr +
                          ", " +
                          book.bookDrive.addrB.addrDetail
                        : book.bookDrive.addrB.nickname
                    } 방문`}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

const StateChip = ({ book }: { book: BookState }) => {
  const [labelText, setLabelText] = useState<string>("서비스 대기 중");
  const [bgColor, setBgColor] = useState<string>("commonColor.main");

  useEffect(() => {
    if (book.paymentInfo.isTemp) {
      setLabelText("임시 저장됨");
      setBgColor("commonColor.alert");
    } else {
      if (book.paymentInfo.bookState === "waiting") {
        setBgColor("commonColor.warning");
        switch (book.paymentInfo.isPaid) {
          case "waiting":
          case "modified-additional":
            setLabelText("입금 대기중");
            break;
          case "modified-refund":
            setLabelText("일부 금액 환불중");
            break;
          case "ok":
            setLabelText("서비스 대기 중");
            setBgColor("commonColor.main");
        }
      } else {
        setBgColor("commonColor.warning");
        switch (book.paymentInfo.bookState) {
          case "cancel-request":
            setLabelText("환불 대기중");
            break;
          case "postpone-request":
            setLabelText("예약 연기 확인중");
            break;
          case "postpone-done":
            setLabelText("예약 연기됨 (쿠폰 확인)");
            break;
          case "service-done":
            setLabelText("서비스 완료");
            setBgColor("commonColor.grey");
            break;
          case "cancel-done":
            setLabelText("예약 취소됨");
            setBgColor("commonColor.grey");
            break;
          case "no-paid-cancel":
            setLabelText("미입금 취소됨");
            setBgColor("commonColor.grey");
            break;
        }
      }
    }
  }, [book]);

  return (
    <Chip
      label={labelText}
      sx={{
        px: 0,
        height: "22px",
        color: "#fff",
        backgroundColor: bgColor,
      }}
    />
  );
};

export default ManageBooksPage;
