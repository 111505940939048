import { Box, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import {
  useBookContext,
  BookState,
  initBookAddr,
} from "../../context/BookContext";
import { firestore } from "../../firebase";
import { dayjsToTimestamp } from "../../components/dispatchFunctions";

export const PrevBookLists = () => {
  const {
    isPC,
    state: { info },
  } = useAuth();
  const { updateBook } = useBookContext();
  const navigate = useNavigate();
  const [books, setBooks] = useState<BookState[]>([]);

  useEffect(() => {
    getPrevBooks();
  }, []);

  const getPrevBooks = async () => {
    const ref = collection(firestore, "books");
    const q = query(ref, where("uid.bookUser", "==", info.uid));

    const datas: BookState[] = [];
    await getDocs(q).then((value) => {
      value.forEach((doc) => {
        const data = doc.data() as BookState;
        datas.push(data);
      });

      datas.sort((a, b) => b.creationDate.seconds - a.creationDate.seconds);
      setBooks(datas.slice(0, 5));
    });
  };

  const onNext = (book: BookState) => {
    const tomorrow = dayjs().add(1, "day");
    book.type = "single";
    book.uid = { date: "", seq: 0, id: "", bookUser: info.uid };
    book.bookDrive.addrA.arrival = dayjsToTimestamp(tomorrow);
    book.bookDrive.addrB.arrival = dayjsToTimestamp(tomorrow);
    book.bookDrive.addrC.arrival = dayjsToTimestamp(tomorrow);
    book.bookDrive.serviceDone = dayjsToTimestamp(tomorrow);
    book.bookDrive.addrPrev = { ...initBookAddr };
    book.bookDrive.addrNext = { ...initBookAddr };
    book.bookDrive.prevUid = "";
    book.bookDrive.nextUid = "";
    book.paymentInfo = {
      isTemp: true,
      isPaid: "waiting",
      bookState: "waiting",
      cashReceiptsDone: false,
      imp_uid: "",
      merchant_uid: "",
      totalFee: 0,
      discount: {
        couponCode: "",
        couponDescription: "",
        coupon: 0,
        point: 0,
      },
      fee: 0,
      paymentType: "card",
    };
    book.isCancelled = false;
    book.isBreakTime = false;
    book.memoToManager = "";

    updateBook(book);
    navigate("../nets");
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
          <Typography color={"primary"}>이전 예약 내역입니다.</Typography>
          <Typography color={"primary"}>최근 5건만 조회됩니다</Typography>
        </Paper>
        {books.map((book, index) => (
          <Paper
            key={index}
            elevation={2}
            sx={{
              display: "flex",
              alignItems: "center",
              py: 2,
              px: 4,
              cursor: "pointer",
            }}
            onClick={() => {
              onNext(book);
            }}
          >
            <Typography sx={{ flex: 1 }}>
              {dayjs(book.uid.date, "YYMMDD").format("YYYY.MM.DD")}
            </Typography>
            {isPC ? (
              <Box
                display={"flex"}
                gap={2}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{ flex: 3 }}
              >
                <Typography sx={{ color: "commonColor.main" }}>
                  {`${book.uid.id} / ${book.patient.name}`}
                </Typography>
                <Typography
                  fontSize={"0.9rem"}
                  sx={{ color: "commonColor.main" }}
                >
                  {book.bookDrive.addrB.nickname !== ""
                    ? book.bookDrive.addrB.nickname
                    : `${book.bookDrive.addrB.addr} ${book.bookDrive.addrB.addrDetail}`}
                </Typography>
                <NavigateNextIcon />
              </Box>
            ) : (
              <Box
                display={"flex"}
                flex={3}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box>
                  <Typography sx={{ color: "commonColor.main" }}>
                    {`${book.uid.id} / ${book.patient.name}`}
                  </Typography>
                  <Typography
                    fontSize={"0.9rem"}
                    sx={{ color: "commonColor.main", mt: 1 }}
                  >
                    {book.bookDrive.addrB.nickname !== ""
                      ? book.bookDrive.addrB.nickname
                      : `${book.bookDrive.addrB.addr} ${book.bookDrive.addrB.addrDetail}`}
                  </Typography>
                </Box>
                <NavigateNextIcon />
              </Box>
            )}
          </Paper>
        ))}
      </Box>
    </>
  );
};
