import {
  Box,
  Button,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import dayjs from "dayjs";
import { useAuth } from "../../context/AuthContext";
import { GeolocationType } from "../../context/BookContext";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Timestamp, deleteDoc, doc, setDoc } from "firebase/firestore";
import { PostCodeButton } from "../../components/postcodeButton";
import { CollapseMessage } from "../../components/collapseMessage";
import { GeolocationBookmark } from "./addressBook";
import { firestore } from "../../firebase";
import { DialogYesNoAction } from "../../components/dialog";
import { gyeonggi, seoul } from "../../model/region";

const AddressBookModifyPage = () => {
  const navigate = useNavigate();
  const {
    isPC,
    state: { info },
  } = useAuth();
  const { state } = useLocation();
  const addrBookmark: GeolocationBookmark | undefined = state.addrBook;
  const newItem: boolean | undefined = state.newItem;

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContext, setDialogContext] = useState<{
    title: string;
    type: "primary" | "alert";
    body: string;
  }>();

  const { index: addrIndex, ...addrBook } = addrBookmark || {
    index: 0,
    addr: "",
    addrDetail: "",
    nickname: "",
    arrival: Timestamp.fromDate(dayjs().toDate()),
    latitude: 0,
    longitude: 0,
    distanceToNext: 0,
    durationToNext: 0,
    feeToNext: 0,
  };

  const index: number | undefined = state.index;

  const [bookAddr, setBookAddr] = useState<GeolocationType>(addrBook);
  const [inavailable, setInavailable] = useState<string | undefined>(undefined);

  const onDialogYes = (type: "primary" | "alert") => {
    if (type === "primary") updateBookmark();
    else onRemove();
  };

  const updateBookmark = async () => {
    if (newItem) {
      if (index !== undefined) {
        const newBookmark: GeolocationBookmark = {
          ...bookAddr,
          index,
        };

        const ref = doc(
          firestore,
          "user-info",
          info.uid,
          "bookmark_address",
          index + ""
        );
        await setDoc(ref, newBookmark);
      }
    } else {
      const newBookmark: GeolocationBookmark = {
        ...bookAddr,
        index: addrIndex,
      };

      const ref = doc(
        firestore,
        "user-info",
        info.uid,
        "bookmark_address",
        addrIndex + ""
      );
      await setDoc(ref, newBookmark);
    }

    navigate(-1);
  };

  const onRemove = async () => {
    const ref = doc(
      firestore,
      "user-info",
      info.uid,
      "bookmark_address",
      addrIndex + ""
    );
    await deleteDoc(ref);
    navigate(-1);
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      <Paper elevation={3} sx={{ py: 2, px: 2 }}>
        <Typography color={"primary"}>
          {newItem ? "주소록을 추가합니다." : "주소록을 수정합니다."}
        </Typography>
      </Paper>
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box
          display={"flex"}
          ml={1}
          mt={1}
          mb={3}
          justifyContent={"space-between"}
          alignContent={"center"}
        >
          <TextField
            label="주소 별칭"
            variant="standard"
            value={bookAddr.nickname}
            onChange={(event) => {
              const temp = { ...bookAddr };
              temp.nickname = event.target.value;
              setBookAddr(temp);
            }}
            InputLabelProps={{ sx: { color: "commonColor.grey" } }}
          />
          {addrBookmark !== undefined && (
            <IconButton
              onClick={() => {
                setDialogContext({
                  type: "alert",
                  title: "주소록 삭제",
                  body: "해당 주소록을 삭제하시겠습니까?",
                });
                setOpenDialog(true);
              }}
            >
              <DeleteForeverIcon color="error" sx={{ fontSize: "48" }} />
            </IconButton>
          )}
        </Box>
        <Box display={"flex"} gap={2} alignItems={"center"} ml={1} mt={1}>
          <TextField
            label="주소"
            variant="standard"
            value={bookAddr.addr}
            InputLabelProps={{ sx: { color: "commonColor.grey" } }}
            sx={{ flex: 1 }}
          />

          <PostCodeButton
            result={(address: GeolocationType) => {
              setInavailable(undefined);
              if (
                seoul.includes(address.addr.split(" ")[0]) ||
                gyeonggi.includes(address.addr.split(" ")[1])
              ) {
                const temp = { ...bookAddr };
                address.nickname = temp.nickname;
                setBookAddr(address);
              } else {
                setInavailable("해당 지역은 운행이 불가능합니다.");
              }
            }}
          />
        </Box>
        <Box display={"flex"} ml={1} mt={1}>
          <TextField
            label="상세 주소"
            variant="standard"
            value={bookAddr.addrDetail}
            onChange={(event) => {
              const temp = { ...bookAddr };
              temp.addrDetail = event.target.value;
              setBookAddr(temp);
            }}
            InputLabelProps={{ sx: { color: "commonColor.grey" } }}
            sx={{ flex: 1 }}
          />
        </Box>
      </Paper>

      <CollapseMessage
        type="warning"
        open={inavailable !== undefined}
        message={inavailable || ""}
        onClose={() => setInavailable(undefined)}
        sx={{ mt: 2, mb: 1 }}
      />

      {isPC ? (
        <Box alignSelf={"end"}>
          <Button
            variant="contained"
            sx={{ mt: 1, gap: 1 }}
            onClick={() => {
              setDialogContext({
                type: "primary",
                title: newItem ? "주소록 추가" : "주소록 수정",
                body: newItem
                  ? "주소록을 추가하시겠습니까?"
                  : "주소록을 수정하시겠습니까?",
              });
              setOpenDialog(true);
            }}
          >
            {newItem ? "주소록 추가" : "주소록 수정"}
          </Button>
        </Box>
      ) : (
        <>
          <Button
            variant="contained"
            sx={{ mt: 1, gap: 1 }}
            onClick={() => {
              setDialogContext({
                type: "primary",
                title: newItem ? "주소록 추가" : "주소록 수정",
                body: newItem
                  ? "주소록을 추가하시겠습니까?"
                  : "주소록을 수정하시겠습니까?",
              });
              setOpenDialog(true);
            }}
          >
            {newItem ? "주소록 추가" : "주소록 수정"}
          </Button>
        </>
      )}

      <DialogYesNoAction
        title={dialogContext ? dialogContext.title : ""}
        color={dialogContext ? dialogContext.type : undefined}
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        onAgree={() => {
          if (dialogContext) {
            setOpenDialog(false);
            onDialogYes(dialogContext.type);
          }
        }}
      >
        <Typography>{dialogContext?.body}</Typography>
      </DialogYesNoAction>
    </Box>
  );
};

export default AddressBookModifyPage;
