import { Box, ButtonBase, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  height: 200,
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.15,
    },
    "& .MuiImageMarked-root": {
      opacity: 0,
    },
  },
}));

const ImageButton2 = styled(ButtonBase)(({ theme }) => ({
  height: 100,
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0,
    },
    "& .MuiImageMarked-root": {
      opacity: 0,
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create("opacity"),
}));

const BookRouter = () => {
  const navigate = useNavigate();
  const { isPC } = useAuth();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      height={isPC ? "316px" : "80vh"}
      mt={2}
    >
      <Box
        display={"flex"}
        flex={isPC ? 1 : 2}
        flexDirection={isPC ? "row" : "column"}
        flexWrap={"wrap"}
        gap={2}
      >
        <ImageButton
          focusRipple
          // style={{ height: "150px" }}
          style={{ flex: 1 }}
          onClick={() => navigate("/reservation")}
        >
          <ImageSrc
            style={{
              backgroundImage: `url(/images/new_book.png)`,
            }}
          />
          <ImageBackdrop className="MuiImageBackdrop-root" />
          <Image>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              sx={{
                fontWeight: 600,
                position: "relative",
                p: 4,
                pt: 2,
                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                textShadow: "-1px 0 grey, 0 1px grey, 1px 0 grey, 0 -1px grey",
              }}
            >
              신규 예약하기
            </Typography>
          </Image>
        </ImageButton>
        <ImageButton
          focusRipple
          // style={{ height: "150px" }}
          style={{ flex: 1 }}
          onClick={() => navigate("/manageBooks")}
        >
          <ImageSrc
            style={{ backgroundImage: `url(/images/check_book.png)` }}
          />
          <ImageBackdrop className="MuiImageBackdrop-root" />
          <Image>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              sx={{
                position: "relative",
                p: 4,
                pt: 2,
                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                textShadow: "-1px 0 grey, 0 1px grey, 1px 0 grey, 0 -1px grey",
              }}
            >
              예약내역 확인하기
            </Typography>
          </Image>
        </ImageButton>
      </Box>
      <Box display={"flex"} flex={1} flexWrap={"wrap"}>
        <ImageButton2
          focusRipple
          style={{ flex: 1 }}
          onClick={() => navigate("/findService")}
        >
          <ImageSrc
            style={{
              backgroundImage: isPC
                ? `url(/images/find_service.png)`
                : `url(/images/find_service_mobile.png)`,
            }}
          />
          {isPC && <ImageBackdrop className="MuiImageBackdrop-root" />}
        </ImageButton2>
      </Box>
    </Box>
  );
};

export default BookRouter;
