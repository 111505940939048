import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Paper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { CollapseMessage } from "../../components/collapseMessage";
import { onChangeUserInfo } from "../../apiCallFunctions/onChangeUserInfo";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../components/loading";
import { DialogYesNoAction } from "../../components/dialog";

export const CancelMembership = () => {
  const navigate = useNavigate();
  const {
    state: { info },
  } = useAuth();
  const [loading, setLoading] = useState(false);
  const [agree, setAgree] = useState(false);
  const [message, setMessage] = useState<string>();
  const [openDialog, setOpenDialog] = useState(false);

  const onClick = () => {
    setMessage(undefined);
    if (!agree) {
      setMessage("회원 탈퇴에 동의해주세요");
      return;
    }

    // 탈퇴
    setLoading(true);
    onChangeUserInfo(info.uid, "leave")
      .then(() => {
        setLoading(false);
        navigate("/", { replace: true });
      })
      .catch((error) => {
        console.log(error);
        setMessage("회원 탈퇴에 실패했습니다");
        setLoading(false);
      });
  };

  return (
    <>
      <Loading open={loading} />
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
        <Typography color={"commonColor.alert"}>
          네츠모빌리티 회원을 탈퇴합니다
        </Typography>
      </Paper>
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Typography color={"commonColor.warning"}>
          회원 탈퇴 관련 유의사항
        </Typography>
        <Box display={"flex"} flexDirection={"column"} mt={1} ml={1} gap={0.5}>
          <Typography>
            - 네츠모빌리티 회원 탈퇴 하시면, 앱 이용내역 (포인트, 쿠폰 포함)이
            모두 삭제되며 탈퇴 후 재가입이 어려울 수 있습니다.
          </Typography>
          <Typography>
            - 현재 예약되어있는 내용이 있는 경우 서비스가 환불되지 않고 취소될
            수 있으니 탈퇴 전 반드시 확인하시기 바랍니다.
          </Typography>
          <Typography>
            - 탈퇴 시 이용하셨던 서비스의 내용은 복원할 수 없으며, 개인정보는
            삭제됩니다.
          </Typography>
          <Typography ml={1}>
            (단, 타 법령에 따라 일정기간 보유가 필요한 경우, 해당 기간동안 보유)
          </Typography>
        </Box>
      </Paper>
      <FormControl sx={{ pl: 1, pt: 1 }} error={!agree}>
        <FormGroup>
          <FormControlLabel
            checked={agree}
            control={
              <Checkbox
                size="small"
                onChange={(_, checked) => {
                  setAgree(checked);
                  setMessage(undefined);
                }}
              />
            }
            label={"회원탈퇴에 동의합니다"}
          />
        </FormGroup>
      </FormControl>

      <CollapseMessage
        type="warning"
        message={message || ""}
        open={message !== undefined}
        onClose={() => setMessage(undefined)}
        sx={{ mt: 1 }}
      />
      <Button
        variant="contained"
        color="error"
        fullWidth
        disabled={loading}
        sx={{ mt: 1 }}
        onClick={() => setOpenDialog(true)}
      >
        회원 탈퇴하기
      </Button>
      <DialogYesNoAction
        title={"회원 탈퇴"}
        color="alert"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onAgree={() => {
          setOpenDialog(false);
          onClick();
        }}
      >
        <Typography>네츠모빌리티 회원을 탈퇴하시겠습니까?</Typography>
      </DialogYesNoAction>
    </>
  );
};
