import {
  Paper,
  Typography,
  Box,
  Button,
  TextField,
  IconButton,
  FormControl,
  Input,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { firestore } from "../../firebase";
import { DialogYesNoAction } from "../../components/dialog";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { PhoneNumberInput } from "../../components/phoneNumberInput";

export const UserInfoModifyPage = () => {
  const navigate = useNavigate();
  const {
    state: { info },
  } = useAuth();

  const [openDialog, setOpenDialog] = useState(false);
  const [name, setName] = useState(info.customer!.name);
  const [phone, setPhone] = useState(info.customer!.phone);
  const [cashReceipts, setCashReceipts] = useState(info.customer!.cashReceipts);

  const onClick = () => {
    setOpenDialog(true);
  };

  const onUpdate = async () => {
    const customerRef = doc(firestore, "user-info", info.uid);
    const newInfo = { ...info };
    newInfo.customer!.name = name;
    newInfo.customer!.phone = phone;
    newInfo.customer!.cashReceipts = cashReceipts;

    await setDoc(customerRef, newInfo);
    navigate(-1);
  };

  useEffect(() => {
    if (!info.customer) navigate("/", { replace: true });
  }, [info]);

  return (
    <>
      <Box>
        <Box display={"flex"} justifyContent={"space-between"} mt={1}>
          <IconButton
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              fontSize: "1rem",
            }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosNewIcon />
            뒤로가기
          </IconButton>
          <Button
            sx={{ alignItems: "end" }}
            onClick={() => navigate("../changePassword")}
          >
            비밀번호 변경
          </Button>
        </Box>
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
          <Typography fontWeight={600}>회원 ID</Typography>
          <Typography>{info.customer!.email}</Typography>
        </Paper>

        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
          <Box display={"flex"} gap={2} pr={1} alignItems={"center"}>
            <Typography fontWeight={600}>성함</Typography>
            <TextField
              variant="standard"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
              InputLabelProps={{ sx: { color: "commonColor.grey" } }}
              sx={{ flex: 1 }}
            />
          </Box>
        </Paper>
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
          <Box display={"flex"} gap={2} pr={1} alignItems={"center"}>
            <Typography fontWeight={600}>전화번호</Typography>
            <FormControl variant="standard" sx={{ flex: 1 }}>
              <Input
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
                inputComponent={PhoneNumberInput as any}
              />
            </FormControl>
          </Box>
        </Paper>
        <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
          <Box display={"flex"} gap={2} pr={1} alignItems={"center"}>
            <Typography fontWeight={600}>현금영수증 번호</Typography>
            <TextField
              variant="standard"
              value={cashReceipts}
              onChange={(event) => {
                setCashReceipts(event.target.value);
              }}
              InputLabelProps={{ sx: { color: "commonColor.grey" } }}
              sx={{ flex: 1 }}
            />
          </Box>
        </Paper>
      </Box>

      <DialogYesNoAction
        title={"회원정보 수정"}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onAgree={() => onUpdate()}
      >
        <Typography>수정하시겠습니까?</Typography>
      </DialogYesNoAction>
      <Button
        variant="contained"
        fullWidth
        sx={{ mt: 2 }}
        onClick={() => onClick()}
      >
        수정 완료
      </Button>
    </>
  );
};
