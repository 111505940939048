import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const tz = "Asia/Seoul";

export const convertListsToObject = (
  object: { value: string; checked: boolean }[],
  options: string[]
) => {
  return object.map((option) => {
    return { ...option, checked: options.includes(option.value) };
  });
};

export const convertObjectToList = (
  object: { value: string; checked: boolean }[]
) => {
  return object
    .filter((option) => option.checked)
    .map((option) => option.value);
};

export const docPath = (path: string) => {
  return path === "" ? "not-exist" : path;
};
export const getTimeString = (time: number) => {
  const min = Math.floor(time / 60);
  const sec = time % 60;
  return `${min}분 ${sec}초`;
};

export const getToday = () => dayjs().tz(tz);
export const setTimezone = (date: Dayjs) => date.tz(tz);

export const getDayjsParseFormat = (time: string, format: string) =>
  dayjs(time, format);

export const getYearMonthDay = (time: Dayjs) => {
  const year = time.year();
  const month = time.month() + 1;
  const day = time.date();

  return { year, month, day };
};
