export const bankCodesLabelValue = [
  { value: "004", label: "KB국민은행" },
  { value: "023", label: "SC제일은행" },
  { value: "034", label: "광주은행" },
  { value: "003", label: "기업은행" },
  { value: "011", label: "농협" },
  { value: "031", label: "대구은행" },
  { value: "032", label: "부산은행" },
  { value: "088", label: "신한은행" },
  { value: "081", label: "하나은행" },
  { value: "020", label: "우리은행" },
  { value: "071", label: "우체국" },
  { value: "089", label: "케이뱅크" },
];
