import { useState, useRef, useEffect } from "react";
import { useThrottle } from "./useThrottle";

export const useResetTimer = (
  // book: BookState,
  onRenew: () => void,
  onClear: (callByTimer: boolean) => void
) => {
  const initTime = 180;
  const [timer, setTimer] = useState(initTime);
  const timerId = useRef<NodeJS.Timer>();
  const timerThrottle = useThrottle(1000);
  const renewThrottle = useThrottle(60000);

  useEffect(() => {
    resetTimer();

    return () => {
      if (timerId.current) clearInterval(timerId.current);
    };
  }, []);

  useEffect(() => {
    if (timer <= 0) {
      if (timerId.current) clearInterval(timerId.current);
      onClear(true);
    }
  }, [timer]);

  const resetTimer = () => {
    if (timerId.current) clearInterval(timerId.current);
    if (timer > 0) {
      setTimer(initTime);
      timerId.current = setInterval(() => {
        setTimer((prev) => {
          return --prev;
        });
      }, 1000);
    }
  };

  const stopTimer = () => {
    if (timerId.current) clearInterval(timerId.current);
  };

  const renewTimer = () => {
    if (timer > 0) {
      console.log("renew");
      onRenew();
    }
  };

  // 키입력이 있을 경우 3분 타이머 초기화
  const onResetThrottle = timerThrottle(resetTimer, 1000);

  // 1분에 한번 creationDate를 갱신해서 스케쥴러가 삭제하지 않도록 방지
  const onRenewThrottle = renewThrottle(renewTimer, 60000);

  return { timer, stopTimer, resetTimer, onResetThrottle, onRenewThrottle };
};
