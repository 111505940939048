import { Box, Typography, Button } from "@mui/material";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const ForgotSendEamil = () => {
  const navigate = useNavigate();
  const state = useLocation().state;

  useEffect(() => {
    if (state === null) navigate("/");
  }, [state]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        // scrollMarginTop: 75,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography color={"primary"} sx={{ textAlign: "center", mb: 4 }}>
          비밀번호 재설정
        </Typography>
        <Typography>
          {`${state.email} 주소로 로 비밀번호 재설정 이메일을 전송했습니다.`}
        </Typography>
        <Typography>비밀번호 재설정 후 로그인해주세요.</Typography>

        <Button
          size="large"
          variant="contained"
          fullWidth
          sx={{ width: "20rem", mt: 2 }}
          onClick={() => navigate("/auth", { replace: true })}
        >
          로그인하러 가기
        </Button>
      </Box>
    </Box>
  );
};
