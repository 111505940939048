import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const ManageBookCancelWaitingPage = () => {
  const navigate = useNavigate();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      height={"90vh"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Typography color={"primary"}>
        예약 취소 및 환불 요청 완료되었습니다.
      </Typography>
      <Typography color={"primary"}>
        관리자 확인 후 환불이 완료됩니다.
      </Typography>

      <Box display={"flex"} justifyContent={"center"}>
        <Button
          variant="contained"
          sx={{ mt: 2 }}
          onClick={() => navigate("/", { replace: true })}
        >
          홈으로 돌아가기
        </Button>
      </Box>
    </Box>
  );
};
