import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Input,
  MobileStepper,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import {
  Close,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import {
  FallsOption,
  PatientSubmitType,
  fallsOptions as fallsOptionsObject,
  useBookContext,
} from "../../../context/BookContext";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../../firebase";
import { useAuth } from "../../../context/AuthContext";
import { PatientBookmark } from "../../mypage/patientInfo";
import {
  convertListsToObject,
  convertObjectToList,
  getTimeString,
} from "../../../components/function";
import { CollapseMessage } from "../../../components/collapseMessage";
import { DialogYesNoAction } from "../../../components/dialog";
import { PhoneNumberInput } from "../../../components/phoneNumberInput";
import { onRemoveBook } from "../../../apiCallFunctions/onRemoveBook";
import { onRenewBook } from "../../../apiCallFunctions/onRenewBook";
import { useResetTimer } from "../../../hooks/useResetTimer";
import { Loading } from "../../../components/loading";

export const PatientPage = () => {
  const navigate = useNavigate();
  const {
    state: { info },
    isPC,
  } = useAuth();
  const {
    book,
    book: { patient, uid, type },
    multiBookUids,
    updatePatient,
  } = useBookContext();
  const [name, setName] = useState(patient.name);
  const [phone, setPhone] = useState(patient.phone);
  const [howToSubmit, setHowToSubmit] = useState(patient.howToSubmit);
  const [fallsOptions, setFallsOptions] = useState<FallsOption[]>(
    convertListsToObject(fallsOptionsObject, patient.fallsOptions)
  );
  const [fallsExtra, setFallsExtra] = useState(patient.fallsExtra);

  const [bookmark, setBookmark] = useState<PatientBookmark[]>([]);
  const [showBookmarkDialog, setShowBookmarkDialog] = useState(false);

  const [message, setMessage] = useState<string>();
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (book.uid.bookUser === "") {
      navigate("/", { replace: true });
    }

    // 다중예약인데 multiId 없으면 오류
    if (type === "multi" && !book.uid.multiId) {
      navigate("/", { replace: true });
    }

    onRenew();

    try {
      const ref = collection(
        firestore,
        "user-info",
        info.uid,
        "bookmark_patient"
      );
      const snapshot = getDocs(ref);

      const bookmarks: PatientBookmark[] = [];
      snapshot.then((data) => {
        data.forEach((data) => {
          if (data.exists()) {
            const p = data.data() as PatientBookmark;
            bookmarks.push(p);
          }
        });
        setBookmark(bookmarks);
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    onResetThrottle();
    onRenewThrottle();
  }, [name, phone, howToSubmit, fallsOptions, fallsExtra]);

  // creationDate 갱신. 2분마다 동작
  const onRenew = async () => {
    try {
      await onRenewBook(type === "single" ? [uid.id] : multiBookUids);
    } catch (error) {
      console.log(error);
    }
  };

  // 타임아웃 시 임시예약 모두 삭제 후 페이지 이동
  const onClear = async (callByTimer: boolean = false) => {
    setLoading(true);

    try {
      await onRemoveBook(type === "single" ? [uid.id] : multiBookUids);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
    navigate("/reservation", { replace: true, state: { callByTimer } });
  };

  const { timer, onResetThrottle, onRenewThrottle } = useResetTimer(
    onRenew,
    onClear
  );

  const onNext = () => {
    setMessage(undefined);
    if (name === "") {
      setMessage("환자 이름을 입력해주세요");
      return;
    } else if (phone === "") {
      setMessage("환자 연락처를 입력해주세요");
      return;
    }

    updatePatient({
      ...patient,
      name,
      phone,
      type: patient.type,
      howToSubmit,
      fallsOptions: convertObjectToList(fallsOptions),
      fallsExtra,
    });

    navigate("../guardian");
  };

  return (
    <Box p={1}>
      {loading && <Loading open={loading} />}
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
        <Typography color={"primary"}>환자 정보 입력</Typography>
        <Typography
          color={"commonColor.warning"}
          sx={
            timer < 60
              ? {
                  animation: "blink-effect 1.5s infinite",
                }
              : {}
          }
        >{`* ${getTimeString(
          timer
        )}  동안 아무 입력이 없으면 예약이 자동으로 취소됩니다.`}</Typography>
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <Typography>환자 성함</Typography>
          <TextField
            placeholder="성함"
            variant="standard"
            value={name}
            onChange={(event) => setName(event.target.value)}
            sx={{ flex: 1 }}
          />
          <Button
            variant="contained"
            size="small"
            sx={{ backgroundColor: "commonColor.grey", borderRadius: 3 }}
            onClick={() => setShowBookmarkDialog(true)}
          >
            불러오기
          </Button>
        </Box>
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <Typography>환자 연락처</Typography>
          <FormControl variant="standard">
            <Input
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
              inputComponent={PhoneNumberInput as any}
            />
          </FormControl>
        </Box>
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
        <Typography color={"primary"}>
          네츠모빌리티는 과기부의 특례를 받아 운영되는 서비스로 날짜, 병원명,
          환자의 성명이 기재된 증빙서류(예약증, 영수증 등)를 반드시 제출해
          주셔야 합니다.
        </Typography>
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Typography>증빙자료 제출 방법</Typography>
        <FormControl sx={{ pl: 1, pt: 1 }}>
          <RadioGroup
            value={howToSubmit}
            onChange={(_, value) =>
              setHowToSubmit(value as unknown as PatientSubmitType)
            }
          >
            <FormControlLabel
              value="kakao"
              control={<Radio size="small" />}
              label="카카오톡 채널 (채널명: 네츠모빌리티)"
            />
            <FormControlLabel
              value="email"
              control={<Radio size="small" />}
              label="이메일 제출 (netsmobility@gmail.com)"
            />
          </RadioGroup>
        </FormControl>
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Typography>낙상 고위험군 해당 여부 체크</Typography>

        <FormControl sx={{ pl: 1, pt: 1 }}>
          <FormGroup>
            {fallsOptions.map((option, index) => (
              <FormControlLabel
                key={index}
                label={option.value}
                value={option.value}
                checked={option.checked}
                control={
                  <Checkbox
                    size="small"
                    onChange={() => {
                      const temp = Array.from(fallsOptions);
                      temp[index].checked = !option.checked;
                      setFallsOptions(temp);
                    }}
                  />
                }
              />
            ))}
          </FormGroup>
          <Box display={"flex"} gap={2} alignItems={"center"} mt={1}>
            <Typography>기타</Typography>
            <TextField
              placeholder="기타 낙상 고위험군 해당사항을 작성"
              variant="standard"
              value={fallsExtra}
              onChange={(event) => setFallsExtra(event.target.value)}
              sx={{ flex: 1 }}
            />
          </Box>
        </FormControl>
      </Paper>

      <CollapseMessage
        type="info"
        message={message || ""}
        open={message !== undefined}
        onClose={() => setMessage(undefined)}
        sx={{ mt: 2 }}
      />

      <Dialog
        open={showBookmarkDialog}
        onClose={() => setShowBookmarkDialog(false)}
        fullScreen={!isPC}
      >
        <DialogTitle
          color={"primary"}
          sx={{
            m: 0,
            py: 2,
            pl: 3,
            pr: 12,
            width: "480px",
            fontSize: "1.0rem",
            backgroundColor: "commonColor.main",
            color: "white",
          }}
        >
          이용자 불러오기
        </DialogTitle>
        <IconButton
          onClick={() => setShowBookmarkDialog(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers sx={{ py: 2 }}>
          {bookmark.map((patient, index) => (
            <Paper
              key={index}
              elevation={2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                py: 2,
                px: 2,
                mb: 1,
                cursor: "pointer",
              }}
              onClick={() => {
                setName(patient.name);
                setPhone(patient.phone);

                setFallsOptions(
                  convertListsToObject(fallsOptionsObject, patient.fallsOptions)
                );
                // setFallsOptions(patient.fallsOptions);
                setFallsExtra(patient.fallsExtra);

                setShowBookmarkDialog(false);
              }}
            >
              <Typography>{patient.nickname}</Typography>
              <Box display={"flex"} gap={2} alignItems={"center"}>
                <Typography
                  fontSize={"0.9rem"}
                  sx={{ color: "commonColor.main" }}
                >
                  {patient.name}
                </Typography>
                <NavigateNextIcon />
              </Box>
            </Paper>
          ))}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setShowBookmarkDialog(false)}>
            닫기
          </Button>
        </DialogActions>
      </Dialog>

      <DialogYesNoAction
        title={"예약 취소"}
        color="alert"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onAgree={() => {
          setOpenDialog(false);
          onClear();
        }}
      >
        <Typography>진행하던 예약 정보가 삭제됩니다.</Typography>
        <Typography>
          입력한 정보 수정이 필요할 경우 새로 예약해주세요
        </Typography>
      </DialogYesNoAction>

      <MobileStepper
        variant="dots"
        steps={7}
        position="static"
        activeStep={5}
        nextButton={
          <Button size="small" disabled={loading} onClick={() => onNext()}>
            다음 <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button
            size="small"
            disabled={loading}
            onClick={() => setOpenDialog(true)}
          >
            <KeyboardArrowLeft />
            예약 취소
          </Button>
        }
        sx={{ mt: message ? 0 : 2 }}
      />
    </Box>
  );
};
