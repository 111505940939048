import axios from "axios";
import { BookState, BookType } from "../context/BookContext";
import { getToday } from "../components/function";
import { doc, setDoc } from "firebase/firestore";
import { firestore } from "../firebase";
import { urls } from "../model/constants";

export type FindCarResult = {
  ok: "ok" | "alter" | "none" | "error";
  date: string; // 목적지 도착시간 YYMMDDHHmm (연, 월, 일, 시, 분)
  uid: string; // firestore 'books' collection에 저장된 document id. ok가 'none' | 'error'일 경우 ''
  message: string; // ok가 error일 경우 에러 메세지. 기본값 ''
};

export const onFindCar = async (
  book: BookState,
  bookDate: string[],
  type: BookType
) => {
  return new Promise<FindCarResult[]>((resolve, reject) => {
    const uid = getToday().format("YYMMDDHHmmSSS");

    setDoc(doc(firestore, "book-for-find-car", uid), book)
      .then(() => {
        axios({
          url: urls.onFindCar,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            book_date: bookDate,
            book_uid: uid,
            book_type: type,
            is_admin: false,
          },
        })
          .then((result) => {
            if (result.status === 200) {
              const data = result.data;
              if (data.status !== "success") throw "error";

              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
