import { Button, Paper, TextField, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { PaymentWaiting, useBookContext } from "../../context/BookContext";
import { useState } from "react";
import { doc, serverTimestamp, setDoc, Timestamp } from "firebase/firestore";
import { firestore } from "../../firebase";
import { CollapseMessage } from "../../components/collapseMessage";
import { useAuth } from "../../context/AuthContext";
import { DialogYesNoAction } from "../../components/dialog";
import dayjs from "dayjs";
import { Loading } from "../../components/loading";
import { onPostponeBook } from "../../apiCallFunctions/onPostponeBook";
export const ManageBookPostponePage = () => {
  const navigate = useNavigate();
  const {
    state: { info },
  } = useAuth();
  const { additionalAmount }: { additionalAmount: number } =
    useLocation().state;
  const { book, updatePaymentInfo } = useBookContext();
  const [reason, setReason] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const onPostpone = async () => {
    setOpenDialog(false);
    setLoading(true);
    if (!book) {
      setLoading(false);
      return;
    }

    if (reason.length <= 0) {
      setErrorMessage("연기 사유를 입력해주세요");
      setLoading(false);
      return;
    }

    try {
      const docId = `${book.uid.id}_${dayjs().format("YYMMDDHHmmssSSS")}`;
      const paymentWaitingref = doc(firestore, "payments-waiting", docId);
      const paymentWaiting: PaymentWaiting = {
        uid: book.uid.id,
        name: info.customer!.name,
        type: "refund-coupon",
        amount: book.paymentInfo.totalFee + additionalAmount,
        reason,
        isDone: false,
        bankCode: "",
        bankAccountHolder: "",
        bankAccount: "",
        refundPercent: 100,
        refundPoint: 0,
        creationDate: Timestamp.now(),
      };

      await setDoc(paymentWaitingref, {
        ...paymentWaiting,
        creationDate: serverTimestamp(),
      });

      onPostponeBook(docId)
        .then(() => {
          navigate("/manageBooks/postponeDone", { replace: true });
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <Loading open={loading} />
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 2 }}>
        <Typography color={"primary"}>
          예약은 1회에 한하여 연기가 가능합니다.
        </Typography>
        <Typography
          sx={{
            mt: 1,
            ml: 1,
            whiteSpace: "pre-line",
            wordBreak: "keep-all",
            lineHeight: "1.6",
          }}
        >
          {`- 예약 연기를 신청하시는 경우 현재 예약은 취소되며, 담당자 확인 후 쿠폰번호가 발급됩니다. 
              (취소된 예약은 다시 발행할 수 없으니 신중하게 연기해주시기 바랍니다.)
            - 신규 예약시 발급된 쿠폰을 결제에 사용하실 수 있으며, 차액은 환불되지 않습니다.
            - 쿠폰은 1회에 한하여 사용이 가능하며, 쿠폰을 사용한 예약을 취소하는 경우에는 환불되지 않습니다.
            - 쿠폰의 사용기한은 6개월이며, 6개월이 지난 후 쿠폰은 자동 소멸됩니다.`}
        </Typography>
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Typography>발급 쿠폰 금액</Typography>
        <Typography>
          {(book.paymentInfo.totalFee + additionalAmount).toLocaleString()}원
        </Typography>
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <TextField
          fullWidth
          variant="standard"
          value={reason}
          label={"연기 사유"}
          onChange={(event) => {
            setReason(event.target.value);
          }}
          InputLabelProps={{ sx: { color: "commonColor.grey" } }}
          sx={{ flex: 1 }}
        />
      </Paper>

      <CollapseMessage
        type="error"
        open={errorMessage !== undefined}
        message={errorMessage || ""}
        onClose={() => setErrorMessage(undefined)}
        sx={{ mt: 2 }}
      />

      <Button
        variant="contained"
        fullWidth
        onClick={() => {
          setErrorMessage(undefined);
          if (reason.length <= 0) {
            setErrorMessage("연기 사유를 입력해주세요");
            return;
          }
          setOpenDialog(true);
        }}
        sx={{ mt: 2 }}
      >
        연기 신청
      </Button>

      <DialogYesNoAction
        title={"예약 연기 신청"}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onAgree={() => onPostpone()}
      >
        <Typography>예약을 연기하시겠습니까?</Typography>
        <Typography>이후 취소는 불가능합니다.</Typography>
      </DialogYesNoAction>
    </>
  );
};
