import { Box, Button, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
export const ManageBookPostponeDonePage = () => {
  const navigate = useNavigate();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      height={"90vh"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Paper elevation={3} sx={{ py: 2, px: 2 }}>
        <Typography color={"primary"}>
          예약 연기 요청이 완료되었습니다. 관리자 확인 후 금액 쿠폰을
          전달해드립니다.
        </Typography>
      </Paper>

      <Box display={"flex"} justifyContent={"center"}>
        <Button
          variant="contained"
          sx={{ mt: 2 }}
          onClick={() => navigate("/", { replace: true })}
        >
          홈으로 돌아가기
        </Button>
      </Box>
    </Box>
  );
};
