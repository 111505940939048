import { Box, Paper, Typography } from "@mui/material";
import { collection, query, getDocs, where } from "firebase/firestore";
import { timestampToDayjs } from "../../components/dispatchFunctions";
import { firestore } from "../../firebase";
import { useEffect, useState } from "react";
import { BoardInfo, useBoardContext } from "../../context/BoardContext";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

export const InfoNoticeListPage = () => {
  const navigate = useNavigate();
  const { isPC } = useAuth();
  const {
    board: { lists },
    updateLists,
    updateIndex,
  } = useBoardContext();

  const [list, setList] = useState<BoardInfo[]>(lists);

  useEffect(() => {
    getNotices();
  }, []);

  useEffect(() => {
    setList(lists);
  }, [lists]);

  const getNotices = () => {
    const ref = collection(firestore, "boards-notice");
    const q = query(ref, where("visible", "==", "공개"));
    getDocs(q)
      .then((res) => {
        let list: BoardInfo[] = [];
        for (let result of res.docs) {
          if (result.exists()) {
            const notice: BoardInfo = result.data() as BoardInfo;
            list.push(notice);
          }
        }

        list.sort((a, b) => b.date.seconds - a.date.seconds);
        updateLists(list);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      {isPC ? (
        <>
          {list.map((notice, index) => (
            <Paper
              key={index}
              elevation={2}
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                py: 2,
                px: 4,
              }}
              onClick={() => {
                updateIndex(index);
                navigate("detail");
              }}
            >
              <Typography fontWeight={600} fontSize={"1.1rem"}>
                {notice.title}
              </Typography>
              <Box
                display={"flex"}
                gap={2}
                sx={{ "& > p": { fontSize: "0.9rem" } }}
              >
                <Typography>{notice.writer}</Typography>
                <Typography>|</Typography>
                <Typography>
                  {timestampToDayjs(notice.date).format("YYYY.MM.DD")}
                </Typography>
              </Box>
            </Paper>
          ))}
        </>
      ) : (
        list.map((notice, index) => (
          <Paper
            key={index}
            elevation={2}
            sx={{ py: 2, px: 4 }}
            onClick={() => {
              updateIndex(index);
              navigate("detail");
            }}
          >
            <Typography fontWeight={600} fontSize={"1.1rem"}>
              {notice.title}
            </Typography>
            <Box
              display={"flex"}
              gap={2}
              mt={1}
              sx={{ "& > p": { fontSize: "0.9rem" } }}
            >
              <Typography>{notice.writer}</Typography>
              <Typography>|</Typography>
              <Typography>
                {timestampToDayjs(notice.date).format("YYYY.MM.DD")}
              </Typography>
            </Box>
          </Paper>
        ))
      )}
      {list.length === 0 && (
        <Paper
          elevation={2}
          sx={{
            py: 2,
            px: 4,
          }}
        >
          <Typography fontSize={"1.1rem"}>게시글이 없습니다</Typography>
        </Paper>
      )}
    </Box>
  );
};
