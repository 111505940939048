import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Input,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import dayjs from "dayjs";
import { useAuth } from "../../context/AuthContext";
import {
  fallsOptions as falls,
  FallsOption,
  PatientType,
} from "../../context/BookContext";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PatientBookmark } from "./patientInfo";
import { deleteDoc, doc, setDoc, Timestamp } from "firebase/firestore";
import { firestore } from "../../firebase";
import {
  convertListsToObject,
  convertObjectToList,
} from "../../components/function";
import { DialogYesNoAction } from "../../components/dialog";
import { DatePicker } from "@mui/x-date-pickers";
import { timestampToDayjs } from "../../components/dispatchFunctions";
import { PhoneNumberInput } from "../../components/phoneNumberInput";

type StateType = {
  state: { patient?: PatientBookmark; newItem?: boolean; index?: number };
};

const PatientInfoModifyPage = () => {
  const navigate = useNavigate();
  const {
    isPC,
    state: { info },
  } = useAuth();

  // useEffect(() => {
  //   console.log(state);
  //   if (state === undefined || state === null)
  //     navigate("/mypage/patientInfo", { replace: true });
  // }, []);

  const { state } = useLocation() as StateType;
  const patient: PatientBookmark | undefined = state?.patient;
  const index: number | undefined = state?.index;
  const newItem: boolean | undefined = state.newItem;

  const [nickname, setNickname] = useState(patient?.nickname || "");
  const [name, setName] = useState(patient?.name || "");
  const [phone, setPhone] = useState(patient?.phone || "");
  const [birthday, setBirthday] = useState<Timestamp>(
    patient?.birthday || Timestamp.now()
  );
  const [type, setType] = useState<PatientType>(patient?.type || "over65");
  const [fallsOptions, setFallsOptions] = useState<FallsOption[]>(
    convertListsToObject(falls, patient?.fallsOptions || [])
  );
  const [fallsExtra, setFallsExtra] = useState(patient?.fallsExtra || "");

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContext, setDialogContext] = useState<{
    title: string;
    type: "primary" | "alert";
    body: string;
  }>();

  const onDialogYes = (type: "primary" | "alert") => {
    if (type === "primary") updateBookmark();
    else onRemove();
  };

  const updateBookmark = async () => {
    if (newItem) {
      if (index !== undefined) {
        const newBookmark: PatientBookmark = {
          index,
          nickname,
          name,
          phone,
          birthday,
          type,
          fallsOptions: convertObjectToList(fallsOptions),
          fallsExtra,
          howToSubmit: "kakao",
        };

        const ref = doc(
          firestore,
          "user-info",
          info.uid,
          "bookmark_patient",
          index + ""
        );
        await setDoc(ref, newBookmark);
      }
    } else {
      if (!patient) return;
      const newBookmark: PatientBookmark = {
        ...patient!,
        nickname,
        name,
        phone,
        birthday,
        type,
        fallsOptions: convertObjectToList(fallsOptions),
        fallsExtra,
      };

      const ref = doc(
        firestore,
        "user-info",
        info.uid,
        "bookmark_patient",
        patient.index + ""
      );
      await setDoc(ref, newBookmark);
    }

    navigate(-1);
  };

  const onRemove = async () => {
    if (patient) {
      const ref = doc(
        firestore,
        "user-info",
        info.uid,
        "bookmark_patient",
        patient.index + ""
      );
      await deleteDoc(ref);
      navigate(-1);
    }
  };
  // {
  //   patient !== undefined && (
  //     <IconButton onClick={() => onRemove()}>
  //       <DeleteForeverIcon color="error" sx={{ fontSize: "48" }} />
  //     </IconButton>
  //   );
  // }

  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      <Paper elevation={3} sx={{ py: 2, px: 2 }}>
        <Typography color={"primary"}>
          {patient
            ? `${name}님 정보 확인 페이지입니다.`
            : `신규 이용자 정보를 추가합니다`}
        </Typography>
      </Paper>
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <Typography>별칭</Typography>
          <TextField
            fullWidth
            variant="standard"
            value={nickname}
            onChange={(event) => {
              setNickname(event.target.value);
            }}
            InputLabelProps={{ sx: { color: "commonColor.grey" } }}
            sx={{ flex: 1 }}
          />
        </Box>
      </Paper>
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <Typography>성함</Typography>
          <TextField
            fullWidth
            variant="standard"
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
            InputLabelProps={{ sx: { color: "commonColor.grey" } }}
            sx={{ flex: 1 }}
          />
        </Box>
      </Paper>
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <Typography>전화번호</Typography>
          <FormControl variant="standard" sx={{ flex: 1 }}>
            <Input
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
              inputComponent={PhoneNumberInput as any}
            />
          </FormControl>
        </Box>
      </Paper>
      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Box display={"flex"} gap={2} alignItems={"center"}>
          <Typography>생년월일</Typography>
          <DatePicker
            value={timestampToDayjs(birthday) || dayjs()}
            onChange={(event) => {
              if (event) setBirthday(Timestamp.fromDate(event.toDate()));
            }}
            sx={{ "& .MuiInputBase-input": { px: 1, py: 0.5 } }}
          />
        </Box>
        {/* <TextField
          fullWidth
          variant="standard"
          value={birthday}
          label={"생년월일"}
          onChange={(event) => {
            setBirthday(event.target.value);
          }}
          InputLabelProps={{ sx: { color: "commonColor.grey" } }}
          sx={{ flex: 1 }}
        /> */}
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Typography>이용 대상자 구분</Typography>
        <FormControl sx={{ pl: 1, pt: 1 }}>
          <RadioGroup
            value={type}
            onChange={(_, value) => setType(value as unknown as PatientType)}
          >
            <FormControlLabel
              value="over65"
              control={<Radio size="small" />}
              label="65세 이상 고령자"
            />
            <FormControlLabel
              value="disabled"
              control={<Radio size="small" />}
              label="장애인"
            />
            <FormControlLabel
              value="limited"
              control={<Radio size="small" />}
              label="거동이 불편한 자"
            />
          </RadioGroup>
        </FormControl>
      </Paper>

      <Paper elevation={3} sx={{ py: 2, px: 2, mt: 1 }}>
        <Typography>낙상 고위험군 해당 여부 체크</Typography>

        <FormControl sx={{ pl: 1, pt: 1 }}>
          <FormGroup>
            {fallsOptions.map((option, index) => (
              <FormControlLabel
                key={index}
                label={option.value}
                value={option.value}
                checked={option.checked}
                control={
                  <Checkbox
                    size="small"
                    onChange={() => {
                      const temp = Array.from(fallsOptions);
                      temp[index].checked = !option.checked;
                      setFallsOptions(temp);
                    }}
                  />
                }
              />
            ))}
          </FormGroup>
          <Box display={"flex"} gap={2} alignItems={"center"} mt={1}>
            <Typography>기타</Typography>
            <TextField
              placeholder="기타 낙상 고위험군 해당사항을 작성"
              variant="standard"
              value={fallsExtra}
              onChange={(event) => setFallsExtra(event.target.value)}
              sx={{ flex: 1 }}
            />
          </Box>
        </FormControl>
      </Paper>

      {isPC ? (
        <Box alignSelf={"end"}>
          {!newItem && (
            <Button
              variant="contained"
              sx={{
                mt: 1,
                mr: 1,
                backgroundColor: "commonColor.alert",
                ":hover": { backgroundColor: "commonColor.alertHover" },
              }}
              onClick={() => {
                setDialogContext({
                  type: "alert",
                  title: "이용자 정보 삭제",
                  body: "이용자 정보를 삭제하시겠습니까?",
                });
                setOpenDialog(true);
              }}
            >
              이용자 정보 삭제
            </Button>
          )}
          <Button
            variant="contained"
            sx={{ mt: 1 }}
            onClick={() => {
              setDialogContext({
                type: "primary",
                title: newItem ? "이용자 정보 추가" : "이용자 정보 수정",
                body: newItem
                  ? "이용자 정보를 추가하시겠습니까?"
                  : "이용자 정보를 수정하시겠습니까?",
              });
              setOpenDialog(true);
            }}
          >
            {newItem ? "이용자 정보 추가" : "이용자 정보 수정"}
          </Button>
        </Box>
      ) : (
        <Box display={"flex"} gap={1}>
          {!newItem && (
            <Button
              variant="contained"
              sx={{
                flex: 1,
                mt: 1,
                backgroundColor: "commonColor.alert",
                ":hover": { backgroundColor: "commonColor.alertHover" },
              }}
              onClick={() => {
                setDialogContext({
                  type: "alert",
                  title: "이용자 정보 삭제",
                  body: "이용자 정보를 삭제하시겠습니까?",
                });
                setOpenDialog(true);
              }}
            >
              이용자 정보 삭제
            </Button>
          )}
          <Button
            variant="contained"
            sx={{
              flex: 1,
              mt: 1,
            }}
            onClick={() => {
              setDialogContext({
                type: "primary",
                title: newItem ? "이용자 정보 추가" : "이용자 정보 수정",
                body: newItem
                  ? "이용자 정보를 추가하시겠습니까?"
                  : "이용자 정보를 수정하시겠습니까?",
              });
              setOpenDialog(true);
            }}
          >
            {newItem ? "이용자 정보 추가" : "이용자 정보 수정"}
          </Button>
        </Box>
      )}

      <DialogYesNoAction
        title={dialogContext ? dialogContext.title : ""}
        color={dialogContext ? dialogContext.type : undefined}
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        onAgree={() => {
          if (dialogContext) {
            setOpenDialog(false);
            onDialogYes(dialogContext.type);
          }
        }}
      >
        <Typography>{dialogContext?.body}</Typography>
      </DialogYesNoAction>
    </Box>
  );
};

export default PatientInfoModifyPage;
