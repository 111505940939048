import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import { Loading } from "../../components/loading";

export const SignUpDone = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      navigate("/", { replace: true });
    }, 500);
  }, []);

  return (
    <>
      <Loading open={loading} />

      <script
        type="text/javascript"
        src="https://wcs.naver.net/wcslog.js"
      ></script>
      <script type="text/javascript">
        var _nasa={}; if(window.wcs) _nasa["cnv"] = wcs.cnv("2","1");
      </script>
    </>
  );
};
