import { Paper, Typography, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ChangePasswordDonePage = () => {
  const navigate = useNavigate();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      height={"90vh"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Paper elevation={3} sx={{ py: 2, px: 2 }}>
        <Typography color={"primary"}>
          비밀번호 변경이 완료되었습니다. 다시 로그인해주세요
        </Typography>
      </Paper>

      <Box display={"flex"} justifyContent={"center"}>
        <Button
          variant="contained"
          sx={{ mt: 2 }}
          onClick={() => navigate("/auth", { replace: true })}
        >
          로그인하러 가기
        </Button>
      </Box>
    </Box>
  );
};

export default ChangePasswordDonePage;
